import { CoatingSpec, CalenderingSpec, SlittingSpec } from "@twinsketch/topika-model";

export function mapTableToCoatingAndCalenderingSpec(table: any[][]) {
    const coatingSpec: CoatingSpec = {
        width: { value: 0, tolerance: 0, unit: "" },
        length: { value: 0, unit: "" },
        area: { value: 0, unit: "" },
        loadingDensityPerSide: { value: 0, unit: "" },
        loadingDensityPerBoth: { value: 0, unit: "" },
        loadingDensityPerBothKg: { value: 0, unit: "" },
        solidContentInSlurry: { value: 0, unit: "" },
        foil: {
            width: { value: 0, tolerance: 0, unit: "" },
            length: { value: 0, unit: "" },
            thickness: { value: 0, unit: "" },
            areaDensity: { value: 0, unit: "" },
            totalArea: { value: 0, unit: "" },
            manufacturer: "Unknown",
        },
    };

    const calenderingSpec: CalenderingSpec = {
        totalThickness: { value: 0, tolerance: 0, unit: "" },
        electrodeDensity: { value: 0, unit: "" },
        calenderedLength: { value: 0, unit: "" },
    };

    const slittingSpec: SlittingSpec = {
        totalSlitWidth: { value: 0, tolerance: 0, unit: "" },
        slitCoatWidth: { value: 0, unit: "" },
        slittingLength: { value: 0, unit: "" },
    };


    const trimmedTable = trimTableToCoatingWidth(table);

    for (const row of trimmedTable) {
        if (row.length === 0) continue; // Skip empty rows

        const [label, rawValue1, unit1, , rawValue2, unit2] = row;
        const value1 = parseFloat(rawValue1) || 0;
        const value2 = parseFloat(rawValue2) || 0;

        if (!label) {
            if (unit1 === "kg/㎡") {
                coatingSpec.loadingDensityPerBothKg = { value: value1, unit: unit1 };
            }
            continue;
        }

        switch (label.trim()) {
            case "Coating width":
                coatingSpec.width = { value: value1, tolerance: parseTolerance(unit1), unit: extractUnit(unit1) };
                break;
            case "Coating length":
                coatingSpec.length = { value: value1, unit: extractUnit(unit1) };
                break;
            case "Sq. meter per a  batch":
            case "Sq. meter":
                coatingSpec.area = { value: value1, unit: unit1 };
                break;
            case "Loading weight(Only A side)":
                coatingSpec.loadingDensityPerSide = { value: value1, unit: unit1 };
                break;
            case "Loading weight(양면)":
                coatingSpec.loadingDensityPerBoth = { value: value1, unit: unit1 };
                break;
            case "Need to solid in slurry":
                coatingSpec.solidContentInSlurry = { value: value1, unit: unit1 };
                break;
            case "Al foil width":
                coatingSpec.foil.width = { value: value1, tolerance: parseTolerance(unit1), unit: extractUnit(unit1) };
                break;
            case "Length":
                coatingSpec.foil.length = { value: value1, unit: extractUnit(unit1) };
                break;
            case "Thickness":
            case "Al foil thickness":
                coatingSpec.foil.thickness = { value: value1, unit: extractUnit(unit1) };
                break;
            case "Area density":
            case "Al foil area density":
                coatingSpec.foil.areaDensity = { value: value1, unit: extractUnit(unit1) };
                break;
            case "Total sq. meter":
                coatingSpec.foil.totalArea = { value: value1, unit: extractUnit(unit1) };
                break;

            // Calendering
            case "Calendering":
            case "3. Calendering spec. ":
                break; // Section headers
            case "Total Thickness":
            case "Total thickness":
                calenderingSpec.totalThickness = { value: value1, tolerance: parseTolerance(unit1), unit: extractUnit(unit1) };
                break;
            case "Electrode density":
                calenderingSpec.electrodeDensity = { value: value1, unit: extractUnit(unit1) };
                break;
            case "Calendered length":
                calenderingSpec.calenderedLength = { value: value1, unit: extractUnit(unit1) };
                break;

            // Slitting
            case "Slitting":
            case "4. Slitting spec. ":
                break; // Section headers
            case "Total slit width":
                slittingSpec.totalSlitWidth = { value: value1, tolerance: parseTolerance(unit1), unit: extractUnit(unit1) };
                break;
            case "Slit coat width":
                slittingSpec.slitCoatWidth = { value: value1, unit: extractUnit(unit1) };
                break;
            case "Slitting length":
                slittingSpec.slittingLength = { value: value1, unit: extractUnit(unit1) };
                break;

            default:
                console.warn(`Unrecognized label: ${label}`);
        }
    }

    return { coating: coatingSpec, calendering: calenderingSpec, slitting: slittingSpec };
}

// Helper function to extract tolerance from a unit string like "±1.0mm"
function parseTolerance(unit: string | null): number | undefined {
    if (!unit) return undefined;
    const match = unit.match(/±([\d.]+)/);
    return match ? parseFloat(match[1]) : undefined;
}

// Helper function to extract the actual unit from a unit string
function extractUnit(unit: string | null): string {
    if (!unit) return "";
    return unit.replace(/±[\d.]+/, "").trim();
}

function findIndexOfCoatingWidth(row: any[]): number {
    return row.findIndex(cell => typeof cell === "string" && cell.trim().toLowerCase() === "coating width");
}

function trimTableToCoatingWidth(table: any[][]): any[][] {
    let beginningRowIndex = -1;
    let beginningColumnIndex = -1;

    // Find the first occurrence of "Coating width"
    for (let rowIndex = 0; rowIndex < table.length; rowIndex++) {
        const colIndex = findIndexOfCoatingWidth(table[rowIndex]);
        if (colIndex !== -1) {
            beginningRowIndex = rowIndex;
            beginningColumnIndex = colIndex;
            break; // Stop after finding the first occurrence
        }
    }

    if (beginningRowIndex === -1 || beginningColumnIndex === -1) {
        console.warn("Coating width not found in table.");
        return table; // Return the original table if not found
    }

    // Trim rows above the starting row
    const trimmedTable = table.slice(beginningRowIndex);

    // Trim columns to start from 'Coating width'
    return trimmedTable.map(row => row.slice(beginningColumnIndex));
}