
import React, { useState } from 'react';
import {
    AmountYield, BinderMixing,
    CalenderingSpec, CoatingSpec,
    Inspection, ProcessYield,
    SlittingSpec,
    SlurryMixing, WorkOrder
} from '@twinsketch/topika-model';
import { WorkOrderPreviewData } from 'src/pages/workOrder/form/workOrder/WorkOrderPreviewService';
import ApiService from 'src/services/ApiService';
import CoatingSpecTable from './tables/CoatingSpecTable';
import InspectionTable from './tables/InspectionTable';
import ProcessYieldTable from './tables/ProcessYieldTable';
import 'src/styles/workOrder/work-order.css'
import 'src/styles/workOrder/mixing-table.css';
import AmountYieldsTable from './tables/AmountYieldTable';
import BinderMixingTable from './tables/BinderMixingTable';
import SlurryMixingTable from './tables/SlurryMixingTable';
import { FormulationToMixingHelper } from '../helper';
import CalenderingSpecTable from './tables/CalenderingSpecTable';
import SlittingSpecTable from './tables/SlittingSpecTable';

export const InstructionNotesToComponent = ({ noteText }: { noteText: string }) => {
    return (
        <div className="column column-2">
            <div style={{ fontSize: '0.9em' }}>
                {noteText
                    .split('\n')
                    .map((line, index) => (
                        <React.Fragment key={index}>
                            {line}
                            <br />
                        </React.Fragment>
                    ))}
            </div>
        </div>
    );
}

export const MixingTableToComponent = ({ mixingTableData }) => {
    // Function to safely format cell content
    const formatCellValue = (cell) => {
        if (cell === null || cell === undefined || cell === '') {
            return '-';
        }
        if (typeof cell === 'number') {
            return cell.toLocaleString(); // Format numbers with commas
        }
        return cell.toString();
    };

    // Separate binder and slurry sections
    const binderSection = mixingTableData.slice(2, 7);
    const slurrySection = mixingTableData.slice(7, 21);
    const mixingTotalSection = mixingTableData.slice(21);

    return (
        <div>
            <div className="table-section">
                <table className="mixing-table" style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                        <tr>
                            <th colSpan={2}>Process</th>
                            <th colSpan={2}>Materials</th>
                            <th>Weight (kg)</th>
                            <th>Density (g/㎤)</th>
                            <th>Volume (ℓ)</th>
                            <th>Lead time (min)</th>
                            <th>Remark</th>
                        </tr>
                    </thead>
                    <tbody>
                        {binderSection.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {row.map((cell, cellIndex) => (
                                    <td key={cellIndex} style={{ border: '1px solid #ddd' }}>
                                        {formatCellValue(cell)}
                                    </td>
                                ))}
                            </tr>
                        ))}
                        {slurrySection.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {row.map((cell, cellIndex) => (
                                    <td key={cellIndex} style={{ border: '1px solid #ddd' }}>
                                        {formatCellValue(cell)}
                                    </td>
                                ))}
                            </tr>
                        ))}
                        {/* {mixingTableData.slice(-1).map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {row.map((cell, cellIndex) => (
                                    <td key={cellIndex} style={{ border: '1px solid #ddd' }}>
                                        {formatCellValue(cell)}
                                    </td>
                                ))}
                            </tr>
                        ))} */}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

interface WorkOrderPreviewProps {
    previewData: WorkOrderPreviewData;
    formulation?: FormulationToMixingHelper;
}
export const WorkOrderPreview: React.FC<WorkOrderPreviewProps> = ({ previewData, formulation }) => {
    const sections = [
        {
            key: "mixingTableData",
            title: "Mixing Table",
            component: <MixingTableToComponent mixingTableData={previewData.mixingTableData!} />,
        },
        {
            key: "slurryMixingTable",
            title: "Mixing Table Preview",
            component: <SlurryMixingTable data={previewData.slurryMixingTable!} formulation={formulation ?? null} />,
        },
        {
            key: "binderMixingTable",
            title: "Binder Table Preview",
            component: <BinderMixingTable data={previewData.binderMixingTable!} formulation={formulation ?? null} />,
        },
        {
            key: "processYieldData",
            title: "Process Yield",
            component: <ProcessYieldTable processYield={previewData.processYieldData!} />,
        },
        {
            key: "materialsNeeded",
            title: "Materials Amount",
            component: <AmountYieldsTable materialsNeeded={previewData.materialsNeeded!} />,
        },
        {
            key: "inspection",
            title: "Inspection",
            component: <InspectionTable inspection={previewData.inspection!} />,
        },
        {
            key: "noteText",
            title: "Instruction Notes",
            component: <InstructionNotesToComponent noteText={previewData.noteText!} />,
        },
        {
            key: "coatingSpecTable",
            title: "Spec Table",
            component: <CoatingSpecTable coatingSpec={previewData.coatingSpecTable!} />,
        },
        {
            key: "calenderingSpecTable",
            title: "Calendaring Table",
            component: <CalenderingSpecTable calenderingSpec={previewData.calenderingSpecTable!} />,
        },
        {
            key: "slittingSpecTable",
            title: "Slitting Table",
            component: <SlittingSpecTable slittingSpec={previewData.slittingSpecTable!} />,
        },
    ];

    const availableSections = sections.filter(section => previewData[section.key as keyof typeof previewData]);

    return (
        <div>
            {availableSections.map(({ title, component }) => (
                <div key={title}>
                    <h2>{title}</h2>
                    {component}
                </div>
            ))}

            {availableSections.length === 0 && <p>No data available to display.</p>}
        </div>
    );
};

function roundNumericValuesForTable(data: any): any {
    if (Array.isArray(data)) {
        // If data is an array, assume it's a list of objects with `net` and `min` values
        return data.map(item => ({
            ...item,
            net: typeof item.net === 'number' ? parseFloat(item.net.toFixed(2)) : item.net,
            min: typeof item.min === 'number' ? parseFloat(item.min.toFixed(2)) : item.min
        }));
    } else if (typeof data === 'object' && data !== null) {
        // If data is an object, round all numeric values
        return Object.fromEntries(
            Object.entries(data).map(([key, value]) =>
                [key, typeof value === 'number' ? parseFloat(value.toFixed(2)) : value]
            )
        );
    }
    return data; // If it's neither an array nor an object, return it as is
}

export const WorkOrderPreviewModal: React.FC<{
    data: WorkOrderPreviewData;
    fileId: string;
    workOrderId: string;
    workOrderData: WorkOrder;
    onClose: () => void
}> = ({ data, fileId, workOrderId, workOrderData, onClose }) => {

    const [isExporting, setIsExporting] = useState(false);
    const handleExportToWorkOrder = async () => {
        setIsExporting(true);

        try {
            // Extract necessary data for Work Order
            const instructionNotes: string = data.noteText;
            const inspection: Inspection = data.inspection;
            const processYield: ProcessYield = roundNumericValuesForTable(data.processYieldData);
            const calcYieldAmount: AmountYield[] = roundNumericValuesForTable(data.materialsNeeded);
            const coatingSpec: CoatingSpec = data.coatingSpecTable;
            const calenderingSpec: CalenderingSpec = data.calenderingSpecTable;
            const slittingSpec: SlittingSpec = data.slittingSpecTable;
            const binderMixing: BinderMixing = data.binderMixingTable;
            const slurryMixing: SlurryMixing = data.slurryMixingTable;

            // Ensure we have existing work order data and a valid work order ID
            if (!workOrderData || !workOrderId) {
                throw new Error("Work order data or ID is missing.");
            }

            // Prepare the updated work order
            const updatedWorkOrder = {
                ...workOrderData,
                instructionNotes: instructionNotes,
                inspection: inspection,

                coatingSpec: coatingSpec,
                calenderingSpec: calenderingSpec,
                slittingSpec: slittingSpec,

                processYield: processYield,
                materialsNeeded: calcYieldAmount,

                binderMixing: binderMixing,
                slurryMixing: slurryMixing,

                originFileId: fileId,
            };

            // Call the API to update the work order
            const updatedWorkOrderResponse = await ApiService.updateWorkOrder(workOrderId, updatedWorkOrder);
            console.log("Updated Work Order:", updatedWorkOrderResponse);
            alert("Successfully exported to work order!");
        } catch (error) {
            console.error("Error exporting to work order:", error);
        } finally {
            setIsExporting(false);
        }
    };

    return (
        <div>
            <div className="modal" style={{ display: 'block', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1000 }}>
                <div className="modal-content" style={{ backgroundColor: 'white', padding: '20px', margin: '50px auto', width: '80%', maxHeight: '80%', overflowY: 'auto', position: 'relative' }}>

                    {/* Button Container at the Top Right */}
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', position: 'absolute', top: '10px', right: '10px' }}>
                        <button onClick={onClose} style={{ padding: '8px 12px', backgroundColor: '#f44336', color: 'white', border: 'none', cursor: 'pointer' }}>
                            Close
                        </button>
                        <button onClick={handleExportToWorkOrder} disabled={isExporting} style={{ padding: '8px 12px', backgroundColor: '#4CAF50', color: 'white', border: 'none', cursor: 'pointer' }}>
                            {isExporting ? 'Exporting...' : 'Export to Work Order'}
                        </button>
                    </div>

                    <div style={{ marginTop: '40px' }}>
                        <WorkOrderPreview previewData={data} />
                    </div>
                </div>
            </div>
        </div>
    );
};
