import { ProcessYield, Measurement } from "@twinsketch/topika-model";
import React from "react";
import 'src/styles/workOrder/work-order.css';
import 'src/styles/workOrder/mixing-table.css';

interface ProcessYieldTableProps {
    processYield: ProcessYield | null | undefined;
}

const formatNumber = (value: number | null | undefined) => {
    return typeof value === "number" ? value.toFixed(2) : "N/A";
};

const ProcessYieldTable: React.FC<ProcessYieldTableProps> = ({ processYield }) => {
    if (!processYield) {
        return <p className="text-gray-500">No process yield data available.</p>;
    }

    return (
        <table className="mixing-table" style={{ width: "440px" }}>
            <thead>
                <tr>
                    <th>Process</th>
                    <th>Yield (%)</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Mixing</td>
                    <td>{formatNumber(processYield.mixing)}</td>
                </tr>
                <tr>
                    <td>Coating</td>
                    <td>{formatNumber(processYield.coating)}</td>
                </tr>
                <tr>
                    <td>Calendering</td>
                    <td>{formatNumber(processYield.calendering)}</td>
                </tr>
                <tr>
                    <td>Slitting</td>
                    <td>{formatNumber(processYield.slitting)}</td>
                </tr>
                <tr>
                    <td>Total</td>
                    <td>{formatNumber(processYield.total)}</td>
                </tr>
            </tbody>
        </table>
    );
};

export default ProcessYieldTable;
