import React, { useState } from "react";
import RenderCell from "../../../../../components/common/RenderCell";
import 'src/styles/workOrder/work-order.css';
import 'src/styles/workOrder/formulation-table.css';

interface TankTableProps {
    mixingTankVolumeFull: number;
    mixingTankVolumeWorking: number;
    onUpdate: (updatedValues: { mixingTankVolumeFull: number; mixingTankVolumeWorking: number }) => void;
}

const TankTable: React.FC<TankTableProps> = ({
    mixingTankVolumeFull,
    mixingTankVolumeWorking,
    onUpdate,
}) => {
    const handleCellChange = (value: string, path: string[]) => {
        const updatedValues = {
            mixingTankVolumeFull,
            mixingTankVolumeWorking,
        };

        if (path.includes("fullVolume")) {
            updatedValues.mixingTankVolumeFull = parseFloat(value) || 0;
        } else if (path.includes("workingVolume")) {
            updatedValues.mixingTankVolumeWorking = parseFloat(value) || 0;
        }

        onUpdate(updatedValues);
    };

    return (
        <div>
            <table className="tank-table">
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Volume</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Full</td>
                        <RenderCell
                            value={mixingTankVolumeFull.toFixed(1)}
                            path={["tank", "fullVolume"]}
                            cellType="editable"
                            fieldType="number"
                            handleChange={handleCellChange}
                        />
                    </tr>
                    <tr>
                        <td>Working</td>
                        <RenderCell
                            value={mixingTankVolumeWorking.toFixed(1)}
                            path={["tank", "workingVolume"]}
                            cellType="editable"
                            fieldType="number"
                            handleChange={handleCellChange}
                        />
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default TankTable;
