import * as XLSX from 'xlsx';
import { ParsedConiditionData, parseConditionData } from './MixingConditionHelper';
import { CompositionData, parseComposition } from './MixingFormulationHelper';
import { InspectionDataResult, parseInspectionData } from './MixingInspectionHelper';
import { OverviewData, parseOverviewData } from './MixingOverviewHelper';
import { ProcessData, extractProcessData } from './MixingProcessHelper';

type DataCell = string | number | null;
type DataRow = DataCell[];

export interface MixingReportPreviewData {
    mixingOverview: OverviewData,
    mixingFormulation: CompositionData[],
    mixingCondition: ParsedConiditionData,
    mixingProcess: ProcessData[],
    mixingInspection: InspectionDataResult,
    notes: string[],
}

function getSheetData(worksheet: XLSX.WorkSheet, range: XLSX.Range): DataRow[] {
    // Implement your logic to extract sheet data based on the range
    // For example, using XLSX.utils.sheet_to_json with range options
    const data: DataRow[] = XLSX.utils.sheet_to_json(worksheet, { header: 1, range });
    return data as DataRow[];
}

function extractSectionDataWithIndices(data: DataRow[], startIndex: number, endIndex: number): DataRow[] {
    return data.slice(startIndex, endIndex);
}

export const findSectionStartIndex = (data: any[][], keyword: string): number => {
    return data.findIndex(
        row => row.some(cell => typeof cell === 'string' && cell.includes(keyword))
    );
};

function extractNotes(rawData: any[][]): string[] {
    const notes: string[] = [];

    // Assuming row 0 is a header like ["4. 특이사항"], so start from row 1:
    const startIndex = 1;

    for (let i = startIndex; i < rawData.length; i++) {
        const row = rawData[i];
        // We expect each row's meaningful note to be in the first column (row[0])
        if (row && row.length > 0) {
            const note = row[0];
            if (typeof note === "string" && note.trim().length > 0) {
                notes.push(note);
            }
        }
    }
    return notes;
}

export interface MixingReportPreviewServiceInterface {
    getFilePreviewData(fileUrl: string): Promise<MixingReportPreviewData>;
}

export class MixingReportPreviewService implements MixingReportPreviewServiceInterface {

    /**
     * Generates HTML preview of the Excel file.
     *
     * @param fileUrl - The URL of the Excel file to preview.
     * @returns A promise that resolves to the HTML string representing the preview.
     */

    async getFilePreviewData(fileUrl: string): Promise<MixingReportPreviewData> {
        try {
            const response = await fetch(fileUrl);
            if (!response.ok) {
                throw new Error(`Failed to fetch the file. Status: ${response.status}`);
            }
            const arrayBuffer = await response.arrayBuffer();
            const workbook = XLSX.read(arrayBuffer, { type: "array" });

            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];

            const mixingReportTable = getSheetData(worksheet, { s: { r: 0, c: 0 }, e: { r: 100, c: 50 } });

            const overviewTableStartIndex = findSectionStartIndex(mixingReportTable, "작업");
            const formulationTableStartIndex = findSectionStartIndex(mixingReportTable, "조성비");
            const processTableStartIndex = findSectionStartIndex(mixingReportTable, "물질 투입");
            const conditionTableStartIndex = findSectionStartIndex(mixingReportTable, "점 도");

            const testTableStartIndex = findSectionStartIndex(mixingReportTable, "검사");
            const noteTableStartIndex = findSectionStartIndex(mixingReportTable, "특이사항");

            const overviewData = this.trimEmptyArrays(
                extractSectionDataWithIndices(mixingReportTable, overviewTableStartIndex, formulationTableStartIndex)
            );
            const formulationData = this.trimEmptyArrays(
                extractSectionDataWithIndices(mixingReportTable, formulationTableStartIndex, processTableStartIndex)
            );
            const processData = this.trimEmptyArrays(
                extractSectionDataWithIndices(mixingReportTable, processTableStartIndex, conditionTableStartIndex)
            );
            const conditionData = this.trimEmptyArrays(
                extractSectionDataWithIndices(mixingReportTable, conditionTableStartIndex, testTableStartIndex)
            );
            const testData = this.trimEmptyArrays(
                extractSectionDataWithIndices(mixingReportTable, testTableStartIndex, noteTableStartIndex)
            );
            const noteData = this.trimEmptyArrays(
                extractSectionDataWithIndices(mixingReportTable, noteTableStartIndex, 100)
            );

            return {
                mixingOverview: parseOverviewData(overviewData),
                mixingFormulation: parseComposition(formulationData),
                mixingCondition: parseConditionData(conditionData),
                mixingProcess: extractProcessData(processData),
                mixingInspection: parseInspectionData(testData),
                notes: extractNotes(noteData)
            };

        } catch (error) {
            console.error("Error generating file preview:", error);
            throw error;
        }
    }

    /**
     * Trims leading and trailing empty arrays from the input array.
     *
     * @param arr - The array to be trimmed.
     * @returns A new array without leading and trailing empty arrays.
     */
    private trimEmptyArrays<T>(arr: T[][]): T[][] {
        const trimmedArray = [...arr];

        // Trim leading empty arrays
        let start = 0;
        while (
            start < trimmedArray.length &&
            Array.isArray(trimmedArray[start]) &&
            trimmedArray[start].length === 0
        ) {
            start++;
        }

        // Trim trailing empty arrays
        let end = trimmedArray.length;
        while (
            end > start &&
            Array.isArray(trimmedArray[end - 1]) &&
            trimmedArray[end - 1].length === 0
        ) {
            end--;
        }

        return trimmedArray.slice(start, end);
    }
}
