export interface CoatingOverviewData {
    productionType: string;
    실내온습도: string;
    workedOn: string;
    headtype: string;
    coatingType: string;
    shim: string;
}

// A helper to convert an Excel serial date (e.g., 45468) to a JS Date.
function excelDateToJSDate(excelSerial) {
    // Excel erroneously considers 1900 a leap year, so subtract 2
    // (1 day if you want to align exactly with how Excel does it,
    //  plus 1 more because JS counts from 0-based).
    // Different versions of Excel handle the offset slightly differently.
    const offset = 2;
    const epoch = new Date(1900, 0, 1); // Jan 1, 1900
    // Move 'epoch' forward 'excelSerial - offset' days
    epoch.setDate(epoch.getDate() + excelSerial - offset);
    return epoch;
}

export function parseCoatingOverviewData(data: any[][]): CoatingOverviewData {
    // Map the exact label strings (keys) to the object properties (values)
    const labelMap = {
        "Production Type :": "productionType",
        "실내 온/습도 :": "실내온습도",
        "제조 일자 :": "제조일자",
        "HEAD Type :": "headtype",
        "Coating Type :": "coatingType",
        "Shim(Gasket) 규격  :": "shim"
    };

    const result: CoatingOverviewData = {
        productionType: null,
        실내온습도: null,
        workedOn: null,
        headtype: null,
        coatingType: null,
        shim: null
    };

    // Helper to format date the way you want
    function formatDate(dateObj) {
        // Example: "Wed, 11152023"
        const options = { weekday: 'short' };
        const weekday = dateObj.toLocaleDateString('en-US', options);
        const mm = String(dateObj.getMonth() + 1).padStart(2, '0');
        const dd = String(dateObj.getDate()).padStart(2, '0');
        const yyyy = dateObj.getFullYear();
        // e.g. "Wed, 11152023"
        return `${weekday}, ${mm}${dd}${yyyy}`;
    }

    for (let row of data) {
        for (let i = 0; i < row.length; i++) {
            const cell = row[i];
            if (typeof cell === 'string' && labelMap[cell.trim()]) {
                const key = labelMap[cell.trim()];

                // -------------------------------
                // Special handling for 실내 온/습도 : 
                // If you expect two consecutive numeric cells for 온도/습도, for example:
                // [ "실내 온/습도 :", null, null, 23.7, 6.7, ... ]
                // then you can read them here:
                if (key === "실내온습도") {
                    // Find next numeric values after the label
                    let temp = null;
                    let humid = null;
                    let lookahead = i + 1;
                    while (lookahead < row.length && (temp === null || humid === null)) {
                        if (typeof row[lookahead] === 'number') {
                            if (temp === null) {
                                temp = row[lookahead];
                            } else {
                                humid = row[lookahead];
                            }
                        }
                        lookahead++;
                    }
                    if (temp !== null && humid !== null) {
                        // e.g. "23.7 C / 6.7"
                        result[key] = `${temp} C / ${humid}`;
                    }
                    continue;
                }

                // -------------------------------
                // Special handling for 제조 일자 : (Excel serial date)
                if (key === "제조일자") {
                    // find the next non-null cell that’s presumably the Excel date
                    for (let j = i + 1; j < row.length; j++) {
                        if (row[j] != null) {
                            // convert from serial date to human-readable
                            if (typeof row[j] === 'number') {
                                const dateObj = excelDateToJSDate(row[j]);
                                result[key] = formatDate(dateObj);
                            } else {
                                // if it’s already a string date
                                result[key] = row[j];
                            }
                            break;
                        }
                    }
                    continue;
                }

                // -------------------------------
                // Default handling for simple labels
                for (let j = i + 1; j < row.length; j++) {
                    if (row[j] != null) {
                        // Save as string or whatever transformation you need
                        // e.g. Lowercase "Slot Die-Nozzle" -> "slot die nozzle"
                        if (key === 'headtype' && typeof row[j] === 'string') {
                            result[key] = row[j].toLowerCase();
                        } else {
                            result[key] = row[j];
                        }
                        break;
                    }
                }
            }
        }
    }

    return result;
}
