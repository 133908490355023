import React from "react";
import 'src/styles/workOrder/mixing-table.css';
import { FormulationToMixingHelper } from "src/pages/workOrder/form/helper";
import { ElectrodeType } from "@twinsketch/topika-model";

interface BinderTableProps {
    data: any;
    formulation: FormulationToMixingHelper;
}

const getSafeValue = (value: any, fallback: string = "-") => {
    if (value === null || value === undefined || value === "") return fallback;
    if (typeof value === "number") return value.toFixed(2);
    const num = parseFloat(value);
    return isNaN(num) ? fallback : num.toFixed(2);
};

const formatLeadTime = (value: any, fallback: string = "-") => {
    if (value === null || value === undefined || value === "") return fallback;
    const num = typeof value === "number" ? value : parseFloat(value);
    return isNaN(num) ? fallback : Math.round(num).toString();
};

const formatSolidContent = (value: any, fallback: string = "0") => {
    const num = typeof value === "number" ? value : parseFloat(value);
    return isNaN(num) ? fallback : `${(num * 100).toFixed(2)}%`;
};

const BinderMixingTable: React.FC<BinderTableProps> = ({ data, formulation }) => {

    const electrodeType = formulation?.formulation?.type;
    let defaultSolventName = "NMP"
    if (electrodeType === ElectrodeType.CATHODE) {
        defaultSolventName = "NMP";
    } else if (electrodeType === ElectrodeType.ANODE) {
        defaultSolventName = "DI Water"
    }

    let binderMaterialName = "-"; // Default fallback value
    const binderMaterials = formulation?.elementsByMaterialType?.Binder || [];
    console.log("formulation contains", binderMaterials)
    if (binderMaterials.length > 0) {
        const firstBinder = binderMaterials[0].material;
        binderMaterialName = `${firstBinder.materialName} - ${firstBinder.modelName}`;
    }

    return (
        <table className="mixing-table">
            <thead>
                <tr>
                    <th colSpan={2}>Material</th>
                    <th>Weight (kg)</th>
                    <th>Density (g/cm³)</th>
                    <th>Volume (L)</th>
                    <th>Mixing Time (min)</th>
                    <th>Comment</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{"Solvent"}</td>
                    <td>{getSafeValue(data?.solvent?.materialName, defaultSolventName)}</td>
                    <td>{getSafeValue(data?.solvent?.weight)}</td>
                    <td>{getSafeValue(data?.solvent?.density)}</td>
                    <td>{getSafeValue(data?.solvent?.volume)}</td>
                    <td>-</td>
                    <td>{getSafeValue(data?.solvent?.comment)}</td>
                </tr>
                {/* Binder materials list */}
                {Array.isArray(data?.binder) && data.binder.map((binderItem, index) => (
                    <tr key={`binder-${index}`}>
                        {index === 0 && (
                            <td rowSpan={data.binder.length}>Binder</td>
                        )}
                        <td>{getSafeValue(binderItem.materialName)}</td>
                        <td>{getSafeValue(binderItem.weight)}</td>
                        <td>{getSafeValue(binderItem.density)}</td>
                        <td>{getSafeValue(binderItem.volume)}</td>
                        <td>-</td>
                        <td>{getSafeValue(binderItem.comment)}</td>
                    </tr>
                ))}
                <tr>
                    <td colSpan={2}>Mixing</td>
                    <td colSpan={3}>-</td>
                    <td>{formatLeadTime(data?.mixingTime)}</td>
                    <td>-</td>
                </tr>
                {/* Solid Content */}
                <tr>
                    <td colSpan={2}>Solid Content</td>
                    <td colSpan={4}>-</td>
                    <td>{formatSolidContent(data?.solidContent)}</td>
                </tr>

                <tr>
                    <td colSpan={2}>Total</td>
                    <td>{getSafeValue(data?.total?.weight)}</td>
                    <td>{getSafeValue(data?.total?.density)}</td>
                    <td>{getSafeValue(data?.total?.volume)}</td>
                    <td>-</td>
                    <td>{getSafeValue(data?.total?.comment)}</td>
                </tr>
            </tbody>
        </table >
    );
};

export default BinderMixingTable;
