import { BinderMixing, SlurryMixing } from "@twinsketch/topika-model";

export function parseMixingTableData(paddedMixingTableData: any[][]) {
    let binderMixing: BinderMixing | null = null;
    let slurryMixing: SlurryMixing | null = null;

    // Utility function to clean and format numeric values
    const sanitizeNumericValue = (value: any): number | null => {
        if (value === null || value === undefined || value === "") return null;
        const cleanedValue = typeof value === "string" ? value.replace(/[^\d.-]/g, "") : value;
        return isNaN(parseFloat(cleanedValue)) || cleanedValue === "" ? null : parseFloat(cleanedValue);
    };


    const splittedTable = splitMixingTableData(paddedMixingTableData);
    const binderData = splittedTable.binder
    const slurryData = splittedTable.slurry;

    // Your logic for building the binder solution goes here
    binderMixing = {
        solvent: { materialName: "", weight: 0, density: 0, volume: 0 },
        binder: [] as {
            materialName: string;
            weight: number;
            density: number;
            volume: number;
            comment: string;
        }[],
        mixingTime: 0,
        solidContent: 0,
        total: { weight: 0, density: 0, volume: 0, comment: "" }
    };

    console.log("Starting to build binder solution...");
    binderData.forEach((row) => {

        const materialType = row[2]; // Material type (e.g., Solvent, CMC, ActiveMaterial)
        const materialName = row[3]; // Actual material name
        const weight = sanitizeNumericValue(row[4]);
        const density = sanitizeNumericValue(row[5]);
        const volume = sanitizeNumericValue(row[6]);
        const leadTime = sanitizeNumericValue(row[7]);
        const comment = row[8] && typeof row[8] === "string" ? row[8] : "-";

        console.log(materialType, materialName)
        if (materialName === "NMP") {
            binderMixing.solvent = { materialName, weight, density, volume };
        } else if (materialType === "Mixing" && leadTime !== 0) {
            binderMixing.mixingTime = leadTime;
        } else if (materialType?.includes("% Solid content")) {
            binderMixing.solidContent = weight;
        } else if (materialType === "Total") {
            binderMixing.total = { weight, density, volume, comment };
        } else {
            binderMixing.binder.push({ materialName, weight, density, volume, comment });
        }
    })


    slurryMixing = {
        premix: {
            solid: { materialName: "", weight: 0, density: 0, volume: 0, comment: "" },
            solvent: { materialName: "", weight: 0, density: 0, volume: 0 },
            carbon: { materialName: "", weight: 0, density: 0, volume: 0 },
            total: { weight: 0, density: 0, volume: 0 },
            mixingTime: 0
        },
        mainMix: { activeMaterial: { materialName: "", weight: 0, density: 0, volume: 0 }, mixingTime: 0 },
        slurry: {
            totalSolidWeight: { weight: 0, density: 0, volume: 0 },
            totalWeight: { weight: 0, density: 0, volume: 0 },
            solidContent: 0
        },
        dilution: { solvent: { materialName: "", weight: 0, density: 0, volume: 0 }, mixingTime: 0, solidContent: 0 },
        degassing: { agitatingPressure: 0, leadTime: 0 },
        total: { weight: 0, density: 0, volume: 0, leadTime: 0, comment: "" }
    };

    let lastValidSection: string | null = null;
    console.log("Starting to build slurry solution...");
    slurryData.forEach((row) => {
        // Skip header rows containing 'Process' or 'Remark'
        if (row[0] === 'Process' || row[row.length - 1] === 'Remark') {
            return;
        }

        // Skip rows where all cells are empty
        if (row.every(cell => cell === null || cell === undefined || cell === '')) {
            return;
        }

        // Extract data from row
        const process = row[0]; // Process name
        const section = row[1] !== undefined && row[1] !== null && row[1] !== "" ? row[1] : lastValidSection;
        const materialType = row[2];
        const materialName = row[3];
        const weight = sanitizeNumericValue(row[4]) ?? 0;
        const density = sanitizeNumericValue(row[5]) ?? 0;
        const volume = sanitizeNumericValue(row[6]) ?? 0;
        const leadTime = sanitizeNumericValue(row[7]) ?? 0;
        const comment = row[8] && typeof row[8] === "string" ? row[8] : "";

        // Update lastValidSection only when a new valid section is found
        if (section !== lastValidSection && section !== null) {
            lastValidSection = section;
        }

        const normalize = (str: string | null | undefined) =>
            (str ?? '') // fallback to empty string if null/undefined
                .normalize('NFKD')
                .replace(/[\u0300-\u036f]/g, '')
                .replace(/[\s\-\.]/g, '')
                .toLowerCase();

        console.log(normalize(section), row);

        if (normalize(section) === "premix") {
            if (materialType === "Mixing") {
                slurryMixing.premix.mixingTime = leadTime;
            } else if (materialName === "Total") {
                slurryMixing.premix.total = { weight, density, volume };
            } else {
                if (materialName === "Solid") {
                    slurryMixing.premix.solid = { materialName, weight, density, volume, comment };
                } else if (materialName === "NMP") {
                    slurryMixing.premix.solvent = { materialName, weight, density, volume };
                } else {
                    slurryMixing.premix.carbon = { materialName, weight, density, volume };
                }
            }
        } else if (normalize(section) === "mainmix" || normalize(section).includes("mixing")) {
            if (materialType === "AM" || materialType.includes("NCM")) {
                slurryMixing.mainMix.activeMaterial = { materialName, weight, density, volume };
            } else if (materialType === "Mixing") {
                slurryMixing.mainMix.mixingTime = leadTime;
            }
        } else if (section === "Slurry") {
            if (matchesKeywords(materialType, ["solid", "total", "weight"], 3)) {
                slurryMixing.slurry.totalSolidWeight = { weight, density, volume };
            } else if (matchesKeywords(materialType, ["total", "weight"], 2)) {
                slurryMixing.slurry.totalWeight = { weight, density, volume };
            } else if (matchesKeywords(materialType, ["solid", "content"], 2)) {
                slurryMixing.slurry.solidContent = weight;
            }
        } else if (normalize(section) === "dilution") {
            if (materialType === "Mixing") {
                slurryMixing.dilution.mixingTime = leadTime;
            } else if (materialType === "NMP") {
                slurryMixing.dilution.solvent = { materialName: materialType, weight, density, volume, comment };
            } else if (matchesKeywords(materialType, ["solid", "content"], 2)) {
                slurryMixing.dilution.solidContent = weight;
            }
        } else if (normalize(section) === "degasing" || normalize(section) === "degassing") {
            if (materialType === "Agitating") {
                slurryMixing.degassing = { agitatingPressure: weight, leadTime };
            } else if (process === "TOTAL slurry weight") {
                slurryMixing.total = { weight, density, volume, leadTime, comment };
            }
        }
    });

    return { binderMixing: binderMixing, slurryMixing: slurryMixing };
}

function splitMixingTableData(paddedMixingTableData: any[][]): { binder: any[][], slurry: any[][] } {
    let binderData: any[][] = [];
    let slurryData: any[][] = [];
    let isBinderSection = false;
    let binderCompleted = false;

    for (const row of paddedMixingTableData) {
        const process = row[0]; // Process name
        const materialType = row[2]; // Material type (e.g., Solvent, CMC, Total)

        // Check if we are entering the Binder Mixing section
        if (!binderCompleted && typeof process === "string" && process.toLowerCase().includes("binder")) {
            isBinderSection = true;
        }


        // If still in binder section, add rows to binderData
        if (isBinderSection) {
            binderData.push(row);

            // If we hit the 'Total' row, binder section ends
            if (materialType === "Total") {
                binderCompleted = true;
                isBinderSection = false;
                continue; // Skip this row from slurryData
            }
        }
        // After binder ends, add remaining rows to slurryData
        else {
            slurryData.push(row);
        }
    }

    return {
        binder: binderData,
        slurry: slurryData
    };
}

const matchesKeywords = (
    materialType: any,
    keywords: string[],
    minMatchCount: number
): boolean => {
    const materialTypeStr = typeof materialType === "string" ? materialType.toLowerCase() : "";
    const matchCount = keywords.filter(keyword =>
        materialTypeStr.includes(keyword.toLowerCase())
    ).length;
    return matchCount >= minMatchCount;
};