import React, { useEffect, useState } from "react";
import { FiExternalLink } from "react-icons/fi";
import { Link } from "react-router-dom";
import { ElementWithMaterialDetails, MaterialType } from "@twinsketch/topika-model";
import { FormulationToMixingHelper } from "src/pages/workOrder/form/helper";
import ROUTES from "src/constants/routes";
import 'src/styles/workOrder/formulation-table.css';

const DynamicTable: React.FC<{
    materialType: string;
    ratio: number;
    elements: ElementWithMaterialDetails[];
}> = ({ materialType, ratio, elements }) => (
    <tr>
        <th>{materialType}</th>
        <td>
            <span>{ratio}%</span>
        </td>
        <td>
            {elements?.length > 0 && (
                <table>
                    <thead>
                        <tr>
                            {elements.map((product, index) => (
                                <th key={`header-${materialType}-${index}`}>{product.material.modelName}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            {elements.map((product, index) => (
                                <td key={`product-${materialType}-${index}`}>
                                    <span>
                                        {((product.ratio / ratio) * 100).toFixed(2)}%
                                    </span>
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </table>
            )}
        </td>
    </tr>
);

const groupElementsByMaterialType = (
    elements: ElementWithMaterialDetails[] | null
): Record<MaterialType, ElementWithMaterialDetails[]> => {
    if (!elements) return {} as Record<MaterialType, ElementWithMaterialDetails[]>;

    return elements.reduce((acc, element) => {
        const type = element.material.materialType;
        if (!acc[type]) {
            acc[type] = [];
        }
        acc[type].push(element);
        return acc;
    }, {} as Record<MaterialType, ElementWithMaterialDetails[]>);
};

interface FormulationTableProps {
    formulation: FormulationToMixingHelper | null;
    setFormulation: React.Dispatch<React.SetStateAction<FormulationToMixingHelper | null>>;
    onFormulationUpdate: (updatedFormulation: FormulationToMixingHelper) => void;
    activeMaterialKg: number;  // New prop for Active Material (kg)

}

const FormulationTable: React.FC<FormulationTableProps> = ({
    formulation,
    setFormulation,
    onFormulationUpdate,
    activeMaterialKg,
}) => {
    const [localFormulation, setLocalFormulation] = useState<FormulationToMixingHelper | null>(null);

    useEffect(() => {
        if (formulation) {
            setLocalFormulation(formulation);
        }
    }, [formulation]);

    if (!formulation || !localFormulation) {
        return <div>Loading formulation data...</div>; // You can also replace this with a spinner if you have one
    }

    const groupedElements = groupElementsByMaterialType(localFormulation.formulation?.elements || []);

    return (
        <div className="formulation-section">
            <div className="formulation-title-row">
                <span className="formulation-title">
                    {localFormulation?.formulation?.name || "No Formulation Selected"}
                </span>
                {localFormulation?.formulation?.id && (
                    <Link
                        to={ROUTES.FORMULATIONS_DETAIL(localFormulation.formulation.id)}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Go to formulation detail"
                        className="formulation-link"
                    >
                        <FiExternalLink size={14} style={{ marginRight: '4px' }} />
                        <span>Formulation Detail</span>
                    </Link>
                )}
            </div>
            <table className="formulation-table">
                <tbody>
                    <tr>
                        <th>Active Material (kg)</th>
                        <td colSpan={2} style={{ fontWeight: 'bold' }}>{activeMaterialKg.toFixed(2)} kg</td>
                    </tr>
                    {localFormulation && Object.keys(localFormulation.ratiosByMaterialType).length > 0 ? (
                        Object.entries(localFormulation.ratiosByMaterialType)
                            .filter(([_, ratio]) => ratio > 0)
                            .map(([materialType, ratio], index) => (
                                <DynamicTable
                                    key={`ratio-${index}`}
                                    materialType={materialType}
                                    ratio={ratio}
                                    elements={groupedElements[materialType as MaterialType] || []}
                                />
                            ))
                    ) : (
                        <tr>
                            <td colSpan={3} style={{ textAlign: 'center' }}>
                                No formulation data available
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default FormulationTable;