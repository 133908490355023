export interface PatternSpec {
    "Top면coating length": number | null;
    "top면coatinglength무지부": number | null;
    "back면coating length": number | null;
    "back면coatinglength무지부": number | null;
}

export interface SpecDataModel {
    topACategory: number | null;
    backBCatebory: number | null;
    foilInput: number | null;
    foilUsed: number | null;

    topLoadingSpec: number | null;
    backLoadingSpec: number | null;
    minTopLoadingSpec: number | null;
    maxTopLoadingSpec: number | null;
    minBackLoadingSpec: number | null;
    maxBackLoadingSpec: number | null;

    topCoatingWidthSpec: number | null;
    backCoatingWidthSpec: number | null;
    minTopCoatingWidthSpec: number | null;
    maxTopCoatingWidthSpec: number | null;
    minBackCoatingWidthSpec: number | null;
    maxBackCoatingWidthSpec: number | null;

    widthArea: number | null;

    patterSpec: PatternSpec | null;
}

export function convertSpecTableToDataModel(
    table: Array<Array<string | number | null>>
): SpecDataModel {
    const result: SpecDataModel = {
        topACategory: null,
        backBCatebory: null,
        foilInput: null,
        foilUsed: null,

        topLoadingSpec: null,
        backLoadingSpec: null,
        minTopLoadingSpec: null,
        maxTopLoadingSpec: null,
        minBackLoadingSpec: null,
        maxBackLoadingSpec: null,

        topCoatingWidthSpec: null,
        backCoatingWidthSpec: null,
        minTopCoatingWidthSpec: null,
        maxTopCoatingWidthSpec: null,
        minBackCoatingWidthSpec: null,
        maxBackCoatingWidthSpec: null,

        widthArea: null,
        patterSpec: null
    };

    // --- Helper to find a row by matching the *first* cell ---
    function findRowByFirstCell(...possibleFirstCells: string[]): (string | number | null)[] | undefined {
        return table.find(row => {
            if (row.length === 0) return false;
            return possibleFirstCells.includes(String(row[0]).trim());
        });
    }

    // --- Parse "Top(A)" row ---
    const topRow = findRowByFirstCell("Top(A)");
    if (topRow) {
        // Example layout for topRow:
        // [
        //   "Top(A)",
        //   1000,               <- topACategory
        //   "Foil 투입량 ",
        //   1400,               <- foilInput
        //   "Top",
        //   10,                 <- topLoadingSpec
        //   9.75,               <- minTopLoadingSpec
        //   10.25,              <- maxTopLoadingSpec
        //   0.25,               <- tolerance (not used)
        //   "Top",
        //   563,                <- topCoatingWidthSpec
        //   null,
        //   562,                <- minTopCoatingWidthSpec
        //   564,                <- maxTopCoatingWidthSpec
        //   1,                  <- tolerance (not used)
        //   250,                <- widthArea
        //   ...
        // ]

        // Safe-get utility
        const safe = (index: number): number | null => {
            if (index >= 0 && index < topRow.length) {
                const val = topRow[index];
                return typeof val === "number" ? val : null;
            }
            return null;
        };

        result.topACategory = safe(1);
        result.foilInput = safe(3);

        // Loading specs
        result.topLoadingSpec = safe(5);
        result.minTopLoadingSpec = safe(6);
        result.maxTopLoadingSpec = safe(7);

        // Coating width specs
        result.topCoatingWidthSpec = safe(10);
        result.minTopCoatingWidthSpec = safe(12);
        result.maxTopCoatingWidthSpec = safe(13);

        // widthArea (sometimes found at column 15 in examples)
        result.widthArea = safe(15);
    }

    // --- Parse "Back(B)" or "Double" row ---
    const backRow = findRowByFirstCell("Back(B)", "Double");
    if (backRow) {
        // Example layout:
        // [
        //   "Back(B)" OR "Double",
        //   1600,           <- backBCatebory
        //   "Foil 사용량 ",
        //   1700,           <- foilUsed
        //   "Back",
        //   40,             <- backLoadingSpec
        //   39.4,           <- minBackLoadingSpec
        //   40.6,           <- maxBackLoadingSpec
        //   0.6,
        //   "Back",
        //   521,            <- backCoatingWidthSpec
        //   ...
        // ]

        const safe = (index: number): number | null => {
            if (index >= 0 && index < backRow.length) {
                const val = backRow[index];
                return typeof val === "number" ? val : null;
            }
            return null;
        };

        result.backBCatebory = safe(1);
        result.foilUsed = safe(3);

        result.backLoadingSpec = safe(5);
        result.minBackLoadingSpec = safe(6);
        result.maxBackLoadingSpec = safe(7);

        result.backCoatingWidthSpec = safe(10);
        result.minBackCoatingWidthSpec = safe(12);
        result.maxBackCoatingWidthSpec = safe(13);
    }

    // --- Parse "Pattern Spec" row (optional) ---
    // Example layout:
    // [
    //   "Pattern Spec",
    //   null,
    //   "Top면 Coating Length",
    //   null,
    //   429,      <- top coating length
    //   null,
    //   "무지부",
    //   32,       <- top 무지부
    //   null,
    //   "Back면 Coating Length",
    //   null,
    //   362,      <- back coating length
    //   null,
    //   "무지부",
    //   99        <- back 무지부
    // ]
    const patternRow = findRowByFirstCell("Pattern Spec");
    if (patternRow) {
        const safe = (index: number): number | null => {
            if (index >= 0 && index < patternRow.length) {
                const val = patternRow[index];
                return typeof val === "number" ? val : null;
            }
            return null;
        };

        result.patterSpec = {
            "Top면coating length": safe(4),
            "top면coatinglength무지부": safe(7),
            "back면coating length": safe(11),
            "back면coatinglength무지부": safe(14)
        };
    }

    return result;
}