import { Inspection } from "@twinsketch/topika-model";
import React from "react";
import 'src/styles/workOrder/mixing-table.css';


interface InspectionTableProps {
    inspection: Inspection | null | undefined;
}

const InspectionTable: React.FC<InspectionTableProps> = ({ inspection }) => {
    if (!inspection) {
        return <p className="text-gray-500">No inspection data available.</p>;
    }

    const formatValue = (value: number | string | null | undefined) => {
        return value !== null && value !== undefined ? value : "N/A";
    };

    return (
        <div>
            <table className="mixing-table">
                <tbody>
                    <tr>
                        <td rowSpan={2} style={{ width: "20%" }}>Binder Solution</td>
                        <td style={{ width: "20%" }}>Solid content</td>
                        <td style={{ width: "60%" }}>
                            {formatValue(inspection.binderSolidContent)}</td>
                    </tr>
                    <tr>
                        <td>Viscosity</td>
                        <td>{formatValue(inspection.binderViscosityRange)}</td>
                    </tr>

                    <tr>
                        <td rowSpan={2}>Slurry</td>
                        <td>Solid content</td>
                        <td>{formatValue(inspection.slurrySolidContent)}</td>
                    </tr>
                    <tr>
                        <td>Viscosity</td>
                        <td >{formatValue(inspection.slurryViscosityRange)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default InspectionTable;
