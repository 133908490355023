import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MixingFormulationWithMaterials, UploadedFile, WorkOrder } from '@twinsketch/topika-model';
import FormulationTable from "src/pages/workOrder/form/FormulationTable";
import { useFetchDataById } from "src/hook/CustomHook";
import ApiService from "src/services/ApiService";
import {
    FormulationToMixingHelper,
    translateToFromulationToMixingHelper
} from "src/pages/workOrder/form/helper";
import ROUTES from "src/constants/routes";
import ErrorModal from "src/components/common/ErrorModal";
import DetailHeader from "src/components/detailPage/DetailHeader";
import { handleEdit } from "src/hook/CommonHandler";
import 'src/styles/workOrder/work-order.css'
import SlurryMixingTable from "./workOrder/tables/SlurryMixingTable";
import BinderMixingTable from "./workOrder/tables/BinderMixingTable";
import CoatingSpecTable from "./workOrder/tables/CoatingSpecTable";
import CalenderingSpecTable from "./workOrder/tables/CalenderingSpecTable";
import SlittingSpecTable from "./workOrder/tables/SlittingSpecTable";
import InspectionTable from "./workOrder/tables/InspectionTable";
import AmountYieldsTable from "./workOrder/tables/AmountYieldTable";
import ProcessYieldTable from "./workOrder/tables/ProcessYieldTable";
import TankTable from "./workOrder/tables/TankTable";

const MinimumWorkOrderForm: React.FC<{}> = () => {

    const { id } = useParams<{ id?: string }>();
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    const [selectedFormulation, setSelectedFormulation] = useState<MixingFormulationWithMaterials | null>(null);
    const [formulationHelper, setFormulationHelper] = useState<FormulationToMixingHelper | null>(null);
    const [draftMessage, setDraftMessage] = useState<string | null>(null);
    const [workOrder, setWorkOrder] = useState<WorkOrder | null>(null);

    useFetchDataById(ApiService.fetchWorkOrderById, id, setWorkOrder, setError);

    useEffect(() => {
        const fetchFormulation = async () => {
            if (workOrder?.formulationId) {
                try {
                    const formulation = await ApiService.fetchFormulationById(workOrder.formulationId);
                    const details = translateToFromulationToMixingHelper(formulation, workOrder.activeMaterialKg);
                    setSelectedFormulation(formulation);
                    setFormulationHelper(details);
                } catch (fetchError) {
                    console.error("Error fetching formulation:", fetchError);
                    setError("Failed to fetch formulation data.");
                }
            }
        };

        fetchFormulation();
    }, [workOrder]);

    useEffect(() => {
        if (workOrder?.files?.length && id) {
            const hasWorkOrderFile = workOrder.files.some((file: UploadedFile) => file.category === "WORK_ORDER");
            if (!hasWorkOrderFile) {
                setDraftMessage("This work order is draft. Please upload a valid work order file to make it ready.");
            }
        }
    }, [workOrder, id]);

    const handleWorkOrderDelete = async () => {
        if (!id) {
            setError("Work order ID is missing.");
            return;
        }
        try {
            await ApiService.deleteWorkOrderById(id);
            alert("Work order deleted successfully.");
            navigate(ROUTES.WORK_ORDERS);
        } catch (deleteError) {
            console.error("Failed to delete work order:", deleteError);
            setError("Failed to delete the work order. Please try again.");
        }
    };

    const updateWorkOrderFlag = async (isFlagged: boolean) => {
        if (!id) return;
        try {
            await ApiService.updateWorkOrder(id, { ...workOrder, flagged: isFlagged });
            setWorkOrder((prevWorkOrder) => ({
                ...prevWorkOrder!,
                flagged: isFlagged
            }));
        } catch (updateError) {
            console.error("Failed to update work order flag:", updateError);
            setError("Failed to update work order flag.");
        }
    };

    const handleEditWorkOrder = () => {
        if (id) {
            handleEdit(id, navigate, setError, ROUTES.WORK_ORDERS_EDIT(id));
        }
    };

    if (!workOrder) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <div className="content-wrapper">
                <ErrorModal message={error} onClose={() => setError(null)} />
                <div>
                    {draftMessage && <div className="error-message" style={{ color: "red", textAlign: "left", marginBottom: "1rem" }}>
                        {draftMessage}
                    </div>}
                </div>
                <div>
                    <div className="form-group">
                        <DetailHeader
                            title={`${workOrder.name || ''}`}
                            handleEdit={handleEditWorkOrder}
                            handleDelete={handleWorkOrderDelete}
                            createdAt={workOrder.createdAt}
                            createdBy={workOrder.createdBy}
                            updatedBy={workOrder.updatedBy}
                        />
                        <label>
                            <input
                                type="checkbox"
                                checked={workOrder.flagged || false}
                                onChange={(e) => updateWorkOrderFlag(e.target.checked)}
                            />
                            Flag this work order
                        </label>
                    </div>
                </div>

                <div className="three-column-layout">
                    <div className="column column-1">
                        <span className="batch-value">
                            <strong>Number of Batches:</strong> {workOrder.numberOfBatch ?? 1}
                        </span>
                        <FormulationTable
                            formulation={formulationHelper}
                            setFormulation={setFormulationHelper}
                            onFormulationUpdate={(updatedFormulation) => {
                                setFormulationHelper(updatedFormulation);
                            }}
                            activeMaterialKg={workOrder.activeMaterialKg}
                        />
                        <div className="section-title">Tank Information</div>
                        <TankTable
                            mixingTankVolumeFull={workOrder.mixingTankVolumeFull || 700}
                            mixingTankVolumeWorking={workOrder.mixingTankVolumeWorking || 480}
                            onUpdate={(updatedValues) =>
                                setWorkOrder((prevWorkOrder) => ({
                                    ...prevWorkOrder,
                                    ...updatedValues,
                                }))
                            }
                        />
                        <div className="section-title">Instruction Notes</div>
                        <textarea
                            id="instructionNotes"
                            value={workOrder.instructionNotes ?? ""}
                            onChange={(e) =>
                                setWorkOrder((prevWorkOrder) => ({
                                    ...prevWorkOrder,
                                    instructionNotes: e.target.value,
                                }))
                            }
                            className="instruction-textarea"
                            rows={4}
                        />
                    </div>
                    <div className="column column-2">
                        <div className="section-title">Binder Mixing</div>
                        <BinderMixingTable data={workOrder.binderMixing} formulation={formulationHelper} />
                        <div className="section-title">Slurry Mixing</div>
                        <SlurryMixingTable data={workOrder.slurryMixing} formulation={formulationHelper} />
                        <div className="section-title">Inspection</div>
                        <InspectionTable inspection={workOrder.inspection} />
                    </div>
                    <div className="column column-3">
                        <div className="section-title" style={{ width: "440px" }}>Coating</div>
                        <CoatingSpecTable coatingSpec={workOrder.coatingSpec} />
                        <div className=" section-title" style={{ width: "440px" }}>Calendering</div>
                        <CalenderingSpecTable calenderingSpec={workOrder.calenderingSpec} />
                        <div className="section-title" style={{ width: "440px" }}>Slitting</div>
                        <SlittingSpecTable slittingSpec={workOrder.slittingSpec} />
                        <div className="section-title" style={{ width: "440px" }}>Material Amount</div>
                        <AmountYieldsTable materialsNeeded={workOrder.materialsNeeded} />
                        <div className="section-title" style={{ width: "440px" }}>Process Yield</div>
                        <ProcessYieldTable processYield={workOrder.processYield} />
                    </div>
                </div>
            </div>
        </div>

    );
};

export default MinimumWorkOrderForm;
