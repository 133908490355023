import { ElectrodeType, SlurryMixing } from "@twinsketch/topika-model";
import React from "react";
import { FormulationToMixingHelper } from "src/pages/workOrder/form/helper";
import 'src/styles/workOrder/mixing-table.css';

interface SlurryTableProps {
    data: any;
    formulation: FormulationToMixingHelper;
}

const getSafeValue = (value: any, fallback: string = "-") => {
    if (value === null || value === undefined || value === "") return fallback;
    if (typeof value === "number") return value.toFixed(2);
    const num = parseFloat(value);
    return isNaN(num) ? fallback : num.toFixed(2);
};

const formatLeadTime = (value: any, fallback: string = "-") => {
    if (value === null || value === undefined || value === "") return fallback;
    const num = typeof value === "number" ? value : parseFloat(value);
    return isNaN(num) ? fallback : Math.round(num).toString();
};

const formatSolidContent = (value: any, fallback: string = "0") => {
    const num = typeof value === "number" ? value : parseFloat(value);
    return isNaN(num) ? fallback : `${(num * 100).toFixed(2)}%`;
};

const getActiveMaterialInfo = (formulation: FormulationToMixingHelper) => {
    let activeMaterialName = "";
    const activeMaterials = formulation?.elementsByMaterialType?.["Active Material"] || [];
    if (activeMaterials.length == 0) {
        console.log("Error")
        return;
    }
    if (activeMaterials.length == 1) {
        const am = activeMaterials[0].material;
        activeMaterialName = `${am.materialName} - ${am.modelName}`;
    }
    return activeMaterialName;
}

const getCAInfo = (formulation: FormulationToMixingHelper, data: SlurryMixing) => {
    const maybeCarbonMaterialName = data.premix?.carbon?.materialName;
    const maybeSolidMaterialName = data.premix?.solid?.materialName;
    const ca = formulation?.elementsByMaterialType?.["Conducting Agent"] || [];

    let carbon = null;
    let solid = null;

    if (maybeCarbonMaterialName) {
        for (const item of ca) {
            if (item.material.materialName === maybeCarbonMaterialName) {
                carbon = item;
            } else if (item.material.materialName === maybeSolidMaterialName) {
                solid = item;
            }
        }
    }
    // Fallback if matching by name fails or names weren't provided
    if (!carbon || !solid) {
        if (ca.length === 2) {
            [carbon, solid] = ca;
        }
    }

    return { carbon, solid };
}

const SlurryMixingTable: React.FC<SlurryTableProps> = ({ data, formulation }) => {

    const electrodeType = formulation?.formulation?.type;
    const defaultSolventName = electrodeType === ElectrodeType.ANODE ? "DI Water" : "NMP";
    const amMaterial = getActiveMaterialInfo(formulation);
    const preMixMaterials = getCAInfo(formulation, data?.premix ?? {});
    const premix = data?.premix ?? {};
    const mainMix = data?.mainMix ?? {};
    const slurry = data?.slurry ?? {};
    const dilution = data?.dilution ?? {};
    const degassing = data?.degassing ?? {};
    const total = data?.total ?? {};

    return (
        <table className="mixing-table">
            <thead>
                <tr>
                    <th>Stage</th>
                    <th>Material</th>
                    <th>Weight (kg)</th>
                    <th>Density (g/cm³)</th>
                    <th>Volume (L)</th>
                    <th>Lead Time (min)</th>
                    <th>Comment</th>
                </tr>
            </thead>
            <tbody>
                {/* Premix Section */}
                <tr>
                    <td rowSpan={5}>Premix</td>
                    <td>{getSafeValue(premix?.solid?.materialName, preMixMaterials.solid?.material.modelName)}</td>
                    <td>{getSafeValue(premix?.solid?.weight)}</td>
                    <td>{getSafeValue(premix?.solid?.density)}</td>
                    <td>{getSafeValue(premix?.solid?.volume)}</td>
                    <td>{getSafeValue(premix?.solid?.comment)}</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>{getSafeValue(premix?.solvent?.materialName, defaultSolventName)}</td>
                    <td>{getSafeValue(premix?.solvent?.weight)}</td>
                    <td>{getSafeValue(premix?.solvent?.density)}</td>
                    <td>{getSafeValue(premix?.solvent?.volume)}</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>Total</td>
                    <td>{getSafeValue(premix?.total?.weight)}</td>
                    <td>{getSafeValue(premix?.total?.density)}</td>
                    <td>{getSafeValue(premix?.total?.volume)}</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>{getSafeValue(premix?.carbon?.materialName, preMixMaterials.carbon?.material.modelName)}</td>
                    <td>{getSafeValue(premix?.carbon?.weight)}</td>
                    <td>{getSafeValue(premix?.carbon?.density)}</td>
                    <td>{getSafeValue(premix?.carbon?.volume)}</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>Mixing</td>
                    <td colSpan={3}>-</td>
                    <td>{formatLeadTime(premix?.mixingTime)}</td>
                    <td>-</td>
                </tr>

                {/* Main Mix Section */}
                <tr>
                    <td rowSpan={2}>Main Mix</td>
                    <td>{amMaterial || "-"}</td>
                    <td>{getSafeValue(mainMix?.activeMaterial?.weight)}</td>
                    <td>{getSafeValue(mainMix?.activeMaterial?.density)}</td>
                    <td>{getSafeValue(mainMix?.activeMaterial?.volume)}</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>Mixing</td>
                    <td colSpan={3}>-</td>
                    <td>{formatLeadTime(mainMix?.mixingTime)}</td>
                    <td>-</td>
                </tr>

                {/* Slurry Section */}
                <tr>
                    <td rowSpan={3}>Slurry</td>
                    <td>Total Solid Weight</td>
                    <td>{getSafeValue(slurry?.totalSolidWeight?.weight)}</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>Total Weight</td>
                    <td>{getSafeValue(slurry?.totalWeight?.weight)}</td>
                    <td>{getSafeValue(slurry?.totalWeight?.density)}</td>
                    <td>{getSafeValue(slurry?.totalWeight?.volume)}</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>Solid Content </td>
                    <td>{formatSolidContent(slurry?.solidContent)}</td>
                    <td colSpan={4}>-</td>
                </tr>

                {/* Dilution Section */}
                <tr>
                    <td rowSpan={3}>Dilution</td>
                    <td>{getSafeValue(dilution?.solvent?.materialName, defaultSolventName)}</td>
                    <td>{getSafeValue(dilution?.solvent?.weight)}</td>
                    <td>{getSafeValue(dilution?.solvent?.density)}</td>
                    <td>{getSafeValue(dilution?.solvent?.volume)}</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>Mixing</td>
                    <td colSpan={3}>-</td>
                    <td>{formatLeadTime(dilution?.mixingTime)}</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>Solid Content</td>
                    <td>{formatSolidContent(dilution?.solidContent)}</td>
                    <td colSpan={4}>-</td>
                </tr>

                {/* Degassing Section */}
                <tr>
                    <td>Degassing</td>
                    <td>Agitating</td>
                    <td>{`${getSafeValue(degassing?.agitatingPressure)}kPa`}</td>
                    <td colSpan={2}>-</td>
                    <td>{formatLeadTime(degassing?.leadTime)}</td>
                    <td>-</td>
                </tr>

                {/* Total Section */}
                <tr>
                    <td colSpan={2}>Total</td>
                    <td>{getSafeValue(total?.weight)}</td>
                    <td>{getSafeValue(total?.density)}</td>
                    <td>{getSafeValue(total?.volume)}</td>
                    <td>{formatLeadTime(total?.leadTime)}</td>
                    <td>{getSafeValue(total?.comment)}</td>
                </tr>
            </tbody>
        </table>
    );
};

export default SlurryMixingTable;
