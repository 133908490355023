import React, { useState } from 'react';
import { UploadedFile, Category } from "@twinsketch/topika-model";
import FileService from "src/services/FileService";
import ApiService from "src/services/ApiService";
import { MixingReportPreviewData, MixingReportPreviewService } from './preview/mixingReport/MixingReportPreviewService';
import { WorkOrderPreviewData, WorkOrderPreviewService } from './form/workOrder/WorkOrderPreviewService';
import { MixingReportPreviewModal } from './preview/mixingReport/DataToComponentHelper';
import { WorkOrderPreviewModal } from './form/workOrder/DataToComponentHelper';
import { CoatingReportPreviewService, CoatingReportPreviewData } from './preview/coatingReport/CoatingReportPreviewService';
import { CoatingReportPreviewModal } from './preview/coatingReport/DataToComponentHelper';

interface RenderFileListProps {
    files: UploadedFile[];
    workOrderId: string | undefined;
    setError: (error: string | null) => void;
    workOrderData: any;
    setWorkOrderData: (data: any) => void;
}

const workOrderPreviewService = new WorkOrderPreviewService();
const mixingReportPreviewService = new MixingReportPreviewService();
const coatingReportPreviewService = new CoatingReportPreviewService();

const RenderFileList: React.FC<RenderFileListProps> = ({ files, workOrderId, setError, workOrderData, setWorkOrderData }) => {
    const categoryOrderMap: Record<Category, number> = {
        [Category.WORK_ORDER]: 1,
        [Category.MIXING_REPORT]: 2,
        [Category.COATING_REPORT]: 3,
        [Category.PRESS_REPORT]: 4,
        [Category.SLITTING_REPORT]: 5,
        [Category.COA]: 6,
    };

    const [previewType, setPreviewType] = useState<
        "WORK_ORDER" | "MIXING_REPORT" | "COATING_REPORT" | "PRESS_REPORT" | "SLITTING_REPORT" | null>(null);
    const [workOrderfileData, setWorkOrderFileData] = useState<WorkOrderPreviewData | null>(null);
    const [mixingReportfileData, setmixingReportFileData] = useState<MixingReportPreviewData | null>(null);
    const [coatingReportFileData, setCoatingReportFileData] = useState<CoatingReportPreviewData | null>(null);

    const [previewVisible, setPreviewVisible] = useState(false);
    const [selectedFileId, setSelectedFileId] = useState<string | null>(null);


    const sortFilesByCategory = (files: UploadedFile[]) => {
        return [...files].sort((a, b) => {
            const orderA = categoryOrderMap[a.category] ?? 999;
            const orderB = categoryOrderMap[b.category] ?? 999;
            return orderA - orderB;
        });
    };

    const handleEditFile = async (fileId: string) => {
        try {
            if (!workOrderId || !workOrderData || !workOrderData.files) return;

            const fileIndex = workOrderData.files.findIndex((f: UploadedFile) => f.id === fileId);
            if (fileIndex < 0) return;

            const currentNotes = workOrderData.files[fileIndex].notes || '';
            const newNotes = prompt('Enter new notes for this file:', currentNotes);
            if (newNotes === null) return;

            const updatedFile = { ...workOrderData.files[fileIndex], notes: newNotes };
            const updatedFiles = [...workOrderData.files];
            updatedFiles[fileIndex] = updatedFile;

            const updatedWorkOrder = { ...workOrderData, files: updatedFiles };

            await ApiService.updateWorkOrder(workOrderId, updatedWorkOrder);
            setWorkOrderData(updatedWorkOrder);
            console.log(`File with ID: ${fileId} updated notes to: ${newNotes}`);
        } catch (err) {
            console.error(err);
            setError('Failed to update file. Please try again.');
        }
    };

    const handleDeleteFile = async (fileId: string) => {
        try {
            if (!workOrderId || !workOrderData || !workOrderData.files) return;

            await FileService.deleteFile(fileId, workOrderId);

            const updatedFiles = workOrderData.files.filter((f: UploadedFile) => f.id !== fileId);
            const updatedWorkOrder = { ...workOrderData, files: updatedFiles };

            await ApiService.updateWorkOrder(workOrderId, updatedWorkOrder);
            setWorkOrderData(updatedWorkOrder);
            console.log(`File with ID: ${fileId} has been deleted.`);
        } catch (err) {
            console.error(err);
            setError('Failed to delete file. Please try again.');
        }
    };

    const handleFilePreview = async (fileId: string, fileUrl: string, fileCategory: Category) => {
        try {
            const files = await FileService.getFiles(workOrderId);

            if (fileCategory === Category.WORK_ORDER) {
                const file = files.find(f => f.category === fileCategory);
                const data = await workOrderPreviewService.getFilePreviewData(file.s3Url);
                setWorkOrderFileData(data);
                setPreviewType("WORK_ORDER");
            } else if (fileCategory === Category.MIXING_REPORT) {
                const data = await mixingReportPreviewService.getFilePreviewData(fileUrl);
                setmixingReportFileData(data);
                setPreviewType("MIXING_REPORT");
                setSelectedFileId(fileId);
            } else if (fileCategory === Category.COATING_REPORT) {
                const data = await coatingReportPreviewService.getFilePreviewData(fileUrl);
                setCoatingReportFileData(data);
                setPreviewType("COATING_REPORT");
                setSelectedFileId(fileId);
            }
            setPreviewVisible(true);
        } catch (error: any) {
            console.error("Error previewing file:", error);
            setError(error.message || 'Failed to preview the file.');
        }
    };

    const handleFileDownload = async (workOrderId, workOrderName, category, fileName) => {
        try {
            // Fetch the list of files for the work order
            const files = await FileService.getFiles(workOrderId);
            const file = files.find(f => f.category === category && f.fileName === fileName);

            if (file && file.s3Url) {
                // Fetch the file data as a Blob
                const response = await fetch(file.s3Url);
                const blob = await response.blob();

                // Determine a file extension from the URL or blob type if needed
                const urlParts = file.s3Url.split('.');

                // Create a temporary anchor element to trigger download
                const link = document.createElement('a');
                const blobUrl = window.URL.createObjectURL(blob);
                link.href = blobUrl;
                // Set the custom filename combining work order name and category
                link.download = `${workOrderName}-${category}-${fileName}`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(blobUrl);
            } else {
                alert('File not found');
            }
        } catch (error) {
            console.error('Error fetching or downloading file:', error);
            alert('An error occurred while fetching the file.');
        }
    };


    if (files.length === 0) {
        return <p>No files uploaded for this work order.</p>;
    }

    const sortedFiles = sortFilesByCategory(files);

    return (
        <ul style={{ listStyleType: 'none', padding: 0 }}>
            {sortedFiles.map((file) => (
                <li
                    key={file.id}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '20px',
                        padding: '10px',
                        borderBottom: '1px solid #ccc',
                    }}
                >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ flex: 1 }}>
                            <a
                                href={file.s3Url}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ fontSize: '16px', fontWeight: 'bold', marginRight: '10px' }}
                            >
                                {file.fileName}
                            </a>
                        </div>
                        <div>
                            {(
                                file.category === Category.WORK_ORDER ||
                                file.category === Category.MIXING_REPORT ||
                                file.category === Category.COATING_REPORT
                            ) && (
                                    <button
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleFilePreview(file.id, file.s3Url, file.category);
                                        }}
                                        style={{
                                            padding: '5px 10px',
                                            backgroundColor: '#2196F3',
                                            color: 'white',
                                            border: 'none',
                                            cursor: 'pointer',
                                            marginRight: '10px',
                                        }}
                                    >
                                        Preview
                                    </button>
                                )}
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleFileDownload(workOrderId, workOrderData?.name ||
                                        "WorkOrder", file.category, file.fileName);
                                }}
                                style={{
                                    padding: '5px 10px',
                                    backgroundColor: '#4CAF50',
                                    color: 'white',
                                    border: 'none',
                                    cursor: 'pointer',
                                }}
                            >
                                Download
                            </button>
                        </div>
                    </div>

                    {file.notes && (
                        <p style={{ fontSize: '14px', color: '#555', margin: '5px 0' }}>
                            <strong>Notes:</strong> {file.notes}
                        </p>
                    )}

                    {file.category && (
                        <p style={{ fontSize: '14px', color: '#555', margin: '5px 0' }}>
                            <strong>Category:</strong> {file.category}
                        </p>
                    )}

                    {file.uploadedAt && (
                        <p style={{ fontSize: '14px', color: '#555', margin: '5px 0' }}>
                            <strong>Uploaded at:</strong> {file.uploadedAt}
                        </p>
                    )}

                    <div>
                        <button
                            onClick={() => handleEditFile(file.id)}
                            style={{
                                marginRight: '10px',
                                padding: '5px 10px',
                                backgroundColor: '#4CAF50',
                                color: 'white',
                                border: 'none',
                                cursor: 'pointer',
                            }}
                        >
                            Edit
                        </button>
                        <button
                            onClick={() => handleDeleteFile(file.id)}
                            style={{
                                padding: '5px 10px',
                                backgroundColor: '#f44336',
                                color: 'white',
                                border: 'none',
                                cursor: 'pointer',
                            }}
                        >
                            Delete
                        </button>
                    </div>
                </li>
            ))}
            {previewVisible && previewType === "WORK_ORDER" && workOrderfileData && (
                <WorkOrderPreviewModal
                    data={workOrderfileData}
                    fileId={selectedFileId}
                    workOrderData={workOrderData}
                    workOrderId={workOrderId}
                    onClose={() => {
                        setPreviewVisible(false);
                        setPreviewType(null);
                    }}
                />
            )}
            {previewVisible && previewType === "MIXING_REPORT" && mixingReportfileData && (
                <MixingReportPreviewModal
                    data={mixingReportfileData}
                    fileId={selectedFileId}
                    workOrderData={workOrderData}
                    workOrderId={workOrderId}
                    onClose={() => {
                        setPreviewVisible(false);
                        setPreviewType(null);
                    }}
                />
            )}
            {previewVisible && previewType === "COATING_REPORT" && coatingReportFileData && (
                <CoatingReportPreviewModal
                    data={coatingReportFileData}
                    fileId={selectedFileId}
                    workOrderData={workOrderData}
                    workOrderId={workOrderId}
                    onClose={() => {
                        setPreviewVisible(false);
                        setPreviewType(null);
                    }}
                />
            )}
        </ul>
    );
};

export default RenderFileList;
