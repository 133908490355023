import * as XLSX from 'xlsx';
import { CoatingOverviewData, parseCoatingOverviewData } from './CoatingOverviewHelper';
import { SpecDataModel, convertSpecTableToDataModel } from './CoatingSpecHelper';

type DataCell = string | number | null;
type DataRow = DataCell[];

export interface ZoneData {
    dryerSupplyFan: number | null;
    dryerExhaustFan: number | null;
    heatSetting: number | null;
    heatSurveying: number | null;
    roomPressure: number | null;
    GasLelPressure: number | null;   // If you prefer, rename to "gasLelPressure"
    damplerSupplyFan: number | null; // If you prefer, rename to "damperSupplyFan"
    damperExhaustFan: number | null; // Possibly "damplerExhaustFan" if needed
}

export interface FloorData {
    tensionUnWinder: number | null;
    CoaterSppedTop: number | null;   // Possibly rename to "coaterSpeedTop"
    CoaterSpeedBack: number | null;
    CoaterSpeedAvg: number | null;
}

export interface ContingCondition {
    firstFloor: {
        zones: ZoneData[],
        floor: FloorData
    };
    sencondFloor: {
        zones: ZoneData[],
        floor: FloorData
    };

    slurryType: string | null;
    actualSolidContent: number | null;
    movedWeight: number | null;
    foil규격: string | null;  // e.g. "576*8"
    제조사: string | null;      // e.g. "Lotte Material"
    foilCondition: string | null;
}

export interface CoatingReportPreviewData {
    overview: CoatingOverviewData,
    spec: SpecDataModel
}

function getSheetData(worksheet: XLSX.WorkSheet, range: XLSX.Range): DataRow[] {
    // Implement your logic to extract sheet data based on the range
    // For example, using XLSX.utils.sheet_to_json with range options
    const data: DataRow[] = XLSX.utils.sheet_to_json(worksheet, { header: 1, range });
    return data as DataRow[];
}

function extractSectionDataWithIndices(data: DataRow[], startIndex: number, endIndex: number): DataRow[] {
    return data.slice(startIndex, endIndex);
}

export const findSectionStartIndex = (data: any[][], keyword: string): number => {
    return data.findIndex(
        row => row.some(cell => typeof cell === 'string' && cell.includes(keyword))
    );
};

function separateByNo(data) {
    const sections = [];
    let currentSection = [];

    for (const row of data) {
        // Check if the first cell is exactly "NO"
        if (row[0] === "NO") {
            // If the current section already has rows, push it into sections
            if (currentSection.length > 0) {
                sections.push(currentSection);
            }
            // Start a new section with this "NO" row
            currentSection = [row];
        } else {
            // Otherwise, keep adding rows to the current section
            currentSection.push(row);
        }
    }

    // If there's a final section that doesn't end with "NO", add it too
    if (currentSection.length > 0) {
        sections.push(currentSection);
    }

    return sections;
}

function trimSectionsByFirstEmptyRow(sections) {
    return sections.map((section) => {
        // Find index of first completely empty row (length == 0)
        const emptyRowIndex = section.findIndex(
            (row) => Array.isArray(row) && row.length === 0
        );

        // If no empty row is found, return the entire section
        if (emptyRowIndex === -1) {
            return section;
        }

        // Return everything up to (but not including) the empty row
        return section.slice(0, emptyRowIndex);
    });
}

/**
 * Trims leading and trailing empty arrays from the input array.
 *
 * @param arr - The array to be trimmed.
 * @returns A new array without leading and trailing empty arrays.
 */
function trimEmptyArrays<T>(arr: T[][]): T[][] {
    const trimmedArray = [...arr];

    // Trim leading empty arrays
    let start = 0;
    while (
        start < trimmedArray.length &&
        Array.isArray(trimmedArray[start]) &&
        trimmedArray[start].length === 0
    ) {
        start++;
    }

    // Trim trailing empty arrays
    let end = trimmedArray.length;
    while (
        end > start &&
        Array.isArray(trimmedArray[end - 1]) &&
        trimmedArray[end - 1].length === 0
    ) {
        end--;
    }

    return trimmedArray.slice(start, end);
}

export interface CoatingReportPreviewServiceInterface {
    getFilePreviewData(fileUrl: string): Promise<CoatingReportPreviewData>;
}


export class CoatingReportPreviewService implements CoatingReportPreviewServiceInterface {

    /**
     * Generates HTML preview of the Excel file.
     *
     * @param fileUrl - The URL of the Excel file to preview.
     * @returns A promise that resolves to the HTML string representing the preview.
     */

    async getFilePreviewData(fileUrl: string): Promise<CoatingReportPreviewData> {
        try {
            const response = await fetch(fileUrl);
            if (!response.ok) {
                throw new Error(`Failed to fetch the file. Status: ${response.status}`);
            }
            const arrayBuffer = await response.arrayBuffer();
            const workbook = XLSX.read(arrayBuffer, { type: "array" });

            const targetSheetNames = workbook.SheetNames.map(name => name.trim());
            const searchString = (data, searchTerm = "생산") =>
                data.flat().some(cell => typeof cell === "string" && cell.includes(searchTerm));

            const processSheets = (workbook) => {
                return targetSheetNames.filter(sheetName => {
                    const thisWorksheet = workbook.Sheets[sheetName];
                    const coatingReportTable = getSheetData(thisWorksheet, { s: { r: 0, c: 0 }, e: { r: 3, c: 3 } });
                    return searchString(coatingReportTable);
                });
            };
            const sheetsToProcess = processSheets(workbook);

            // need to loop for the all sheets in filterdSheetNames
            const firstSheetName = sheetsToProcess[0];
            const worksheet = workbook.Sheets[firstSheetName];

            const coatingReportTable = trimEmptyArrays(
                getSheetData(worksheet, { s: { r: 0, c: 0 }, e: { r: 100, c: 50 } }));

            const overviewTableStartIndex = findSectionStartIndex(coatingReportTable, "Production Type");
            const specTableStartIndex = findSectionStartIndex(coatingReportTable, "구분");
            const conditionTableStartIndex = findSectionStartIndex(coatingReportTable, "Condision");
            const trialTableStartIndex = findSectionStartIndex(coatingReportTable, "NO");

            const overviewData = trimEmptyArrays(
                extractSectionDataWithIndices(coatingReportTable, overviewTableStartIndex, specTableStartIndex)
            );
            const specData = trimEmptyArrays(
                extractSectionDataWithIndices(coatingReportTable, specTableStartIndex, conditionTableStartIndex)
            );
            const conditionData = trimEmptyArrays(
                extractSectionDataWithIndices(coatingReportTable, conditionTableStartIndex, trialTableStartIndex)
            );
            const trialData = trimEmptyArrays(
                extractSectionDataWithIndices(coatingReportTable, trialTableStartIndex, 100)
            );
            const sectionedTrialData = trimSectionsByFirstEmptyRow(separateByNo(trialData));

            return {
                overview: parseCoatingOverviewData(overviewData),
                spec: convertSpecTableToDataModel(specData)
            };

        } catch (error) {
            console.error("Error generating file preview:", error);
            throw error;
        }
    }

}
