import React, { useState } from 'react';

import { CoatingReportPreviewData } from './CoatingReportPreviewService';
import { CoatingOverviewData } from './CoatingOverviewHelper';
import { CoatingWork, WorkOrder } from '@twinsketch/topika-model';
import ApiService from 'src/services/ApiService';
import { formatDateToYYYYMMDD } from '../mixingReport/DataToComponentHelper';

// Utility function to format cell values
const formatCellValue = (cell: any) => {
    if (cell === null || cell === undefined || cell === '') {
        return '-';
    }
    if (typeof cell === 'number') {
        return cell.toLocaleString(); // Format numbers with commas
    }
    return cell.toString();
};

// ==================== Overview Table ====================
interface CoatingOverviewTableProps {
    data: CoatingOverviewData;
}

export const CoatingOverviewTable: React.FC<CoatingOverviewTableProps> = ({ data }) => {
    return (
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <thead>
                <tr>
                    <th style={{ border: '1px solid #ccc', padding: '8px' }}>Production Type</th>
                    <th style={{ border: '1px solid #ccc', padding: '8px' }}>실내온습도</th>
                    <th style={{ border: '1px solid #ccc', padding: '8px' }}>제조일자</th>
                    <th style={{ border: '1px solid #ccc', padding: '8px' }}>Head Type</th>
                    <th style={{ border: '1px solid #ccc', padding: '8px' }}>Coating Type</th>
                    <th style={{ border: '1px solid #ccc', padding: '8px' }}>Shim</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td style={{ border: '1px solid #ccc', padding: '8px' }}>{data.productionType}</td>
                    <td style={{ border: '1px solid #ccc', padding: '8px' }}>{data.실내온습도}</td>
                    <td style={{ border: '1px solid #ccc', padding: '8px' }}>{data.workedOn}</td>
                    <td style={{ border: '1px solid #ccc', padding: '8px' }}>{data.headtype}</td>
                    <td style={{ border: '1px solid #ccc', padding: '8px' }}>{data.coatingType}</td>
                    <td style={{ border: '1px solid #ccc', padding: '8px' }}>{data.shim}</td>
                </tr>
            </tbody>
        </table>
    );
};

// ==================== Example Usage: Combining all in a single component ====================

interface CoatingReportPreviewProps {
    previewData: CoatingReportPreviewData;
}

export const CoatingReportPreview: React.FC<CoatingReportPreviewProps> = ({ previewData }) => {
    return (
        <div>
            <CoatingOverviewTable data={previewData.overview} />
        </div>
    );
};

export const CoatingReportPreviewModal: React.FC<{
    data: CoatingReportPreviewData;
    fileId: string;
    workOrderId: string;
    workOrderData: WorkOrder;
    onClose: () => void
}> = ({ data, fileId, workOrderId, workOrderData, onClose }) => {

    const [isExporting, setIsExporting] = useState(false);

    const handleExportToWorkOrder = async () => {
        setIsExporting(true);

        try {
            // Extract necessary data for mixing work
            const workedOn = formatDateToYYYYMMDD(data.overview.workedOn);
            const coatingData: CoatingWork = {
                workedOn: workedOn,
                headType: data.overview.headtype,
                coatingType: data.overview.coatingType, // Stripe-Coating or Pattern-Coating
                shimSpec: data.overview.shim, // 211*0.5R*1.0T
                filterPressure: null, 
                dyePressure: null,
                originFileId: fileId,
            };

            // Ensure we have existing work order data and a valid work order ID
            if (!workOrderData || !workOrderId) {
                throw new Error("Work order data or ID is missing.");
            }

            let updatedCoatingWork;
            if (workOrderData.mixingWork) {
                const existingCoatingWorkIndex = workOrderData.mixingWork.findIndex(mw => mw.originFileId && mw.originFileId === fileId);
                if (existingCoatingWorkIndex !== -1) {
                    // Update the existing mixingWork entry
                    updatedCoatingWork = [...workOrderData.mixingWork];
                    updatedCoatingWork[existingCoatingWorkIndex] = {
                        ...updatedCoatingWork[existingCoatingWorkIndex],
                        ...coatingData  // Merge new data with existing data
                    };
                } else {
                    // Append a new mixingWork entry
                    updatedCoatingWork = [...workOrderData.mixingWork, coatingData];
                }
            }

            //         // Prepare the updated work order
            //         const updatedWorkOrder = {
            //             ...workOrderData,
            //             coatingWork: updatedCoatingWork
            //         };
            //         // Call the API to update the work order
            //         const updatedWorkOrderResponse = await ApiService.updateWorkOrder(workOrderId, updatedWorkOrder);
            //         alert("Successfully exported to work order!");
        } catch (error) {
            console.error("Error exporting to work order:", error);
        } finally {
            setIsExporting(false);
        }
    };

    return (
        <div>
            <div className="modal" style={{ display: 'block', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1000 }}>
                <div className="modal-content" style={{ backgroundColor: 'white', padding: '20px', margin: '50px auto', width: '80%', maxHeight: '80%', overflowY: 'auto', position: 'relative' }}>
                    {/* Button Container at the Top Right */}
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', position: 'absolute', top: '10px', right: '10px' }}>
                        <button onClick={onClose} style={{ padding: '8px 12px', backgroundColor: '#f44336', color: 'white', border: 'none', cursor: 'pointer' }}>
                            Close
                        </button>
                        {/* <button onClick={handleExportToWorkOrder} disabled={isExporting} style={{ padding: '8px 12px', backgroundColor: '#4CAF50', color: 'white', border: 'none', cursor: 'pointer' }}>
                            {isExporting ? 'Exporting...' : 'Export to Work Order'}
                        </button> */}
                    </div>
                    <div style={{ marginTop: '40px' }}>
                        <CoatingReportPreview previewData={data} />
                    </div>
                </div>
            </div>
        </div >
    );
};
