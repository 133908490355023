import { CoatingSpec, Measurement } from "@twinsketch/topika-model";
import React from "react";
import 'src/styles/workOrder/work-order.css';
import 'src/styles/workOrder/mixing-table.css';

interface CoatingSpecTableProps {
    coatingSpec: CoatingSpec | null | undefined;
}

const formatNumber = (value: number | undefined | null) => {
    return typeof value === "number" ? value.toFixed(2) : "N/A";
};

const formatMeasurement = (measurement: Measurement | null | undefined) => {
    if (!measurement || measurement.value === null || measurement.value === undefined) return "N/A";
    const value = formatNumber(measurement.value);
    const tolerance = measurement.tolerance !== undefined ? ` ±${formatNumber(measurement.tolerance)}` : "";
    const unit = measurement.unit ? ` ${measurement.unit}` : "";
    return `${value}${tolerance}${unit}`;
};

const CoatingSpecTable: React.FC<CoatingSpecTableProps> = ({ coatingSpec }) => {
    if (!coatingSpec) {
        return <p>No coating specifications available.</p>;
    }

    return (
        <table className="mixing-table" style={{ width: "440px" }}>
            <tbody>
                <tr className="row">
                    <td>Coating Width</td>
                    <td>{formatMeasurement(coatingSpec.width)}</td>
                </tr>
                <tr className="row">
                    <td>Coating Length</td>
                    <td>{formatMeasurement(coatingSpec.length)}</td>
                </tr>
                <tr className="row">
                    <td>Coating Area</td>
                    <td>{formatMeasurement(coatingSpec.area)}</td>
                </tr>
                <tr className="row">
                    <td>Loading Density (Single Side)</td>
                    <td>{formatMeasurement(coatingSpec.loadingDensityPerSide)}</td>
                </tr>
                <tr className="row">
                    <td>Loading Density (Both Sides)</td>
                    <td>{formatMeasurement(coatingSpec.loadingDensityPerBoth)}</td>
                </tr>
                <tr className="row">
                    <td>Loading Density (Both Sides kg/㎡)</td>
                    <td>{formatMeasurement(coatingSpec.loadingDensityPerBothKg)}</td>
                </tr>
                <tr className="row">
                    <td>Solid Content in Slurry</td>
                    <td>{formatMeasurement(coatingSpec.solidContentInSlurry)}</td>
                </tr>

                {/* Spacer Row */}
                <tr><td colSpan={2} style={{ height: "10px" }} /></tr>

                {/* Section Title Row */}
                <tr className="row section-header">
                    <td colSpan={2}>Foil Specifications</td>
                </tr>
            </tbody>
            <tbody>
                <tr className="row">
                    <td>Foil Width</td>
                    <td>{formatMeasurement(coatingSpec.foil?.width)}</td>
                </tr>
                <tr className="row">
                    <td>Foil Length</td>
                    <td>{formatMeasurement(coatingSpec.foil?.length)}</td>
                </tr>
                <tr className="row">
                    <td>Foil Thickness</td>
                    <td>{formatMeasurement(coatingSpec.foil?.thickness)}</td>
                </tr>
                <tr className="row">
                    <td>Foil Area Density</td>
                    <td>{formatMeasurement(coatingSpec.foil?.areaDensity)}</td>
                </tr>
                <tr className="row">
                    <td>Foil Total Area</td>
                    <td>{formatMeasurement(coatingSpec.foil?.totalArea)}</td>
                </tr>
                <tr className="row">
                    <td>Foil Manufacturer</td>
                    <td>{coatingSpec.foil?.manufacturer || "N/A"}</td>
                </tr>
            </tbody>

        </table>
    );
};

export default CoatingSpecTable;
