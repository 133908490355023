import { Measurement, SlittingSpec } from "@twinsketch/topika-model";
import React from "react";

interface SlittingSpecTableProps {
    slittingSpec: SlittingSpec | null | undefined;
}

const formatNumber = (value: number | undefined | null) => {
    return typeof value === "number" ? value.toFixed(2) : "-";
};

const formatMeasurement = (measurement: Measurement | null | undefined) => {
    if (!measurement || measurement.value === null || measurement.value === undefined) return "-";
    const value = formatNumber(measurement.value);
    const tolerance = measurement.tolerance !== undefined ? ` ±${formatNumber(measurement.tolerance)}` : "";
    const unit = measurement.unit ? ` ${measurement.unit}` : "";
    return `${value}${tolerance}${unit}`;
};

const SlittingSpecTable: React.FC<SlittingSpecTableProps> = ({ slittingSpec }) => {
    if (!slittingSpec) {
        return <p className="text-gray-500">No slitting specifications available.</p>;
    }

    return (
        <table className="mixing-table" style={{ width: "440px" }}>
            <tbody>
                <tr className="row">
                    <td>Slit Coat Width</td>
                    <td>{formatMeasurement(slittingSpec.slitCoatWidth)}</td>
                </tr>
                <tr className="row">
                    <td>Slitting Length</td>
                    <td>{formatMeasurement(slittingSpec.slittingLength)}</td>
                </tr>
                <tr className="row">
                    <td>Total Slit Width</td>
                    <td>{formatMeasurement(slittingSpec.totalSlitWidth)}</td>
                </tr>
            </tbody>
        </table>
    );
};

export default SlittingSpecTable;

