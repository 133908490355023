import { CalenderingSpec, Measurement } from "@twinsketch/topika-model";
import React from "react";

interface CalenderingSpecTableProps {
    calenderingSpec: CalenderingSpec | null | undefined;
}

const formatNumber = (value: number | undefined | null) => {
    return typeof value === "number" ? value.toFixed(2) : "-";
};

const formatMeasurement = (measurement: Measurement | null | undefined) => {
    if (!measurement || measurement.value === null || measurement.value === undefined) return "-";
    const value = formatNumber(measurement.value);
    const tolerance = measurement.tolerance !== undefined ? ` ±${formatNumber(measurement.tolerance)}` : "";
    const unit = measurement.unit ? ` ${measurement.unit}` : "";
    return `${value}${tolerance}${unit}`;
};

const CalenderingSpecTable: React.FC<CalenderingSpecTableProps> = ({ calenderingSpec }) => {
    if (!calenderingSpec) {
        return <p className="text-gray-500">No calendering specifications available.</p>;
    }

    return (
        <table className="mixing-table" style={{ width: "440px" }}>
            <tbody>
                <tr className="row">
                    <td>Electrode Density</td>
                    <td>{formatMeasurement(calenderingSpec.electrodeDensity)}</td>
                </tr>
                <tr className="row">
                    <td>Total Thickness</td>
                    <td>{formatMeasurement(calenderingSpec.totalThickness)}</td>
                </tr>

                <tr className="row">
                    <td>Calendered Length</td>
                    <td>{formatMeasurement(calenderingSpec.calenderedLength)}</td>
                </tr>
            </tbody>
        </table>
    );
};

export default CalenderingSpecTable;

