import { AmountYield, BinderMixing, CalenderingSpec, CoatingSpec, Inspection, ProcessYield, SlittingSpec, SlurryMixing, } from '@twinsketch/topika-model';
import * as XLSX from 'xlsx';
import { checkWorkbookProtection, DataRow, getSheetData, trimEmptyArrays } from '../PreiviewUtils';
import { mapTableToCoatingAndCalenderingSpec } from './SpecTableHelper';
import { parseMixingTableData } from './MixingTableParser';

// Define the return type for better type safety
export interface WorkOrderPreviewData {
    mixingTableData: any[][];

    binderMixingTable: BinderMixing;
    slurryMixingTable: SlurryMixing;

    inspection: Inspection;
    noteText: string;

    materialsNeeded: AmountYield[];
    processYieldData: ProcessYield;

    coatingSpecTable: CoatingSpec;
    calenderingSpecTable: CalenderingSpec;
    slittingSpecTable: SlittingSpec;
}

function extractSectionDataWithIndices(data: DataRow[], startIndex: number, endIndex: number): DataRow[] {
    return data.slice(startIndex, endIndex);
}

export const findSectionStartIndex = (data: any[][], keyword: string): number => {
    return data.findIndex(
        row => row.some(cell => typeof cell === 'string' && cell.includes(keyword))
    );
};

export function toInspection(data: any[][]): Inspection {
    const binderSolidRaw = parseFloat(data[1][4]); // 0.08
    const binderSolidContent = `${(binderSolidRaw * 100).toFixed(2)}% ${data[1][5]}`; // ± 1.00%

    const binderViscosityRange = `${data[2][4]} ${data[2][5]} ${data[2][6]}`.trim();

    const slurrySolidRaw = parseFloat(data[3][4]); // 0.6033442509912085
    const slurrySolidContent = `${(slurrySolidRaw * 100).toFixed(2)}% ${data[3][5]} ${data[3][6]}`.trim();

    const slurryViscosityRange = `${data[4][4]} ${data[4][5]} ${data[4][6]}`.trim();

    return {
        binderSolidContent,
        binderViscosityRange,
        slurrySolidContent,
        slurryViscosityRange,
    };
}


export const findCoatingWidthIndex = (data: any[][]): number => {
    return findSectionStartIndex(data, "Coating width");
};

export const toExtractNotes = (data: any[][], nextSectionStartIndex): string => {
    return data.slice(0, nextSectionStartIndex - 1)
        .map(row => row.filter(cell => cell != null).join(' '))
        .join('\n');
};

export function convertToMaterialAmount(data: any[][]): AmountYield[] {

    let amountYields: AmountYield[] = [];
    data.forEach((row, index) => {
        if (index === 0) return; // skip the header row
        if (!row[0]) return; // skip empty or undefined rows

        if (row?.[2] !== undefined && isNaN(parseFloat(row[2]))) {
            amountYields.push({
                materialName: String(row?.[0] ?? "N/A"),
                net: row?.[1] !== undefined && !isNaN(parseFloat(row[1])) ? parseFloat(row[1]) : 0,
                min: row?.[3] !== undefined && !isNaN(parseFloat(row[3])) ? parseFloat(row[3]) : 0,
            });
        } else {

            amountYields.push({
                materialName: String(row?.[0] ?? "N/A"),
                net: row?.[1] !== undefined && !isNaN(parseFloat(row[1])) ? parseFloat(row[1]) : 0,
                min: row?.[2] !== undefined && !isNaN(parseFloat(row[2])) ? parseFloat(row[2]) : 0,
            });
        }

    });
    return amountYields;
}

export function convertToProcessYield(data: any[][]): ProcessYield {
    // The rows containing Mix, Coat, Cal, Slit, Total yields
    // are assumed to follow the '% Yield' row in the exact order below.
    const mixRow = data[1]; // e.g., ["NMP", ..., "Mix", 0.95]
    const coatRow = data[2]; // e.g., ["코스모신소재 NCM523", ..., "Coat", 0.8]
    const calRow = data[3]; // e.g., ["도전재(불용자재 사용)", ..., "Cal", 0.95]
    const slitRow = data[4]; // e.g., ["Solvay 5130...", ..., "Slit", 0.98]
    const totalRow = data[5]; // e.g., [null, 0, "kg", 0, "kg", "Total", 0.70756]

    // Parse numeric yield values from the last column of each row
    const mixing = (parseFloat(mixRow?.[1]) || 0) * 100;
    const coating = (parseFloat(coatRow?.[1]) || 0) * 100;
    const calendering = (parseFloat(calRow?.[1]) || 0) * 100;
    const slitting = (parseFloat(slitRow?.[1]) || 0) * 100;
    const total = (parseFloat(totalRow?.[1]) || 0) * 100;

    return {
        mixing,
        coating,
        calendering,
        slitting,
        total,
        calculateTotalYield() {
            // Convert each percentage back to a decimal for the multiplication
            // then multiply the final product by 100 to get it as a percentage.
            //
            // e.g., if mixing = 95, coating = 80, calendering = 95, slitting = 98,
            // the calculation is:
            //   (95/100) * (80/100) * (95/100) * (98/100) * 100 ≈ 70.756
            const mixDec = this.mixing / 100;
            const coatDec = this.coating / 100;
            const calDec = this.calendering / 100;
            const slitDec = this.slitting / 100;
            return mixDec * coatDec * calDec * slitDec * 100;
        },
    };
}


export interface WorkOrderPreviewServiceInterface {
    getFilePreviewData(fileUrl: string): Promise<WorkOrderPreviewData>;
}

/**
 * Determines if a worksheet is a work order.
 * @param {string} sheetName - The name of the sheet.
 * @param {object} workbook - The workbook containing the sheet.
 * @returns {string | null} - The sheet name if it's identified as a work order, null otherwise.
 */
function getWorkOrderSheetName(sheetName, workbook) {
    const worksheet = workbook.Sheets[sheetName];
    if (!worksheet) {
        console.warn(`Worksheet not found for sheet name: ${sheetName}`);
        return null;
    }

    // Get relevant data from the worksheet
    const firstColumnSheet = getSheetData(worksheet, { s: { r: 0, c: 1 }, e: { r: 40, c: 9 } });

    // Determine if the worksheet contains sections for "Process" or "Inspection"
    const mixingTableStartIndex = findSectionStartIndexWithRegex(firstColumnSheet, "Process");
    const inspectionTableStartIndex = findSectionStartIndexWithRegex(firstColumnSheet, "Inspection");

    if (mixingTableStartIndex !== -1 || inspectionTableStartIndex !== -1) {
        return sheetName;
    }

    return null;
}

export const findSectionStartIndexWithRegex = (data: any[][], keyword: string): number => {
    // Create a case-insensitive regex without word boundaries to be more flexible
    const regex = new RegExp(keyword, 'i');

    return data.findIndex(row =>
        row.some(cell => {
            if (typeof cell === 'string') {
                // Normalize the string by removing special characters and trimming whitespace
                const normalizedCell = cell.replace(/[\W_]+/g, ' ').trim();
                return regex.test(normalizedCell);
            }
            return false;
        })
    );
};

function getCellsBeforeKeyword(cells: DataRow, keyword: string): DataRow {
    // Create a regex with word boundaries for case-insensitive matching
    const keywordRegex = new RegExp(`\\b${keyword}\\b`, 'i');

    // Find the index of the first cell that contains the keyword
    const keywordIndex = cells.findIndex(cell => {
        // Ensure the cell is a non-null string before testing with regex
        return typeof cell === 'string' && keywordRegex.test(cell);
    });

    // If no such cell is found, return all cells; otherwise, return cells up to the found index
    return keywordIndex === -1 ? cells : cells.slice(0, keywordIndex);
}

function trimColumnsFromFront(matrix: (string | number | null)[][], x: number): (string | number | null)[][] {
    return matrix.map(row => row.slice(x));
}

function buildMixingTable(firstColumnSheet: DataRow[]) {
    const mixingTableStartIndex = findSectionStartIndexWithRegex(firstColumnSheet, "process");
    const mixingTableEndIndex = findSectionStartIndexWithRegex(firstColumnSheet, "inspection");
    const mixingTableData = trimEmptyArrays(
        extractSectionDataWithIndices(firstColumnSheet, mixingTableStartIndex, mixingTableEndIndex)
    );

    const paddedMixingTableData = mixingTableData.map(row => {
        const paddedRow = [...row];
        while (paddedRow.length < 9) {
            paddedRow.push(null);
        }
        return paddedRow;
    });

    return paddedMixingTableData;
}

function buildInspectionTable(firstColumnSheet: DataRow[]) {
    const inspectionTableStartIndex = findSectionStartIndexWithRegex(firstColumnSheet, "Inspection");
    const inspectionTableData = trimEmptyArrays(
        extractSectionDataWithIndices(firstColumnSheet, inspectionTableStartIndex, inspectionTableStartIndex + 6)
    );
    const inspection = toInspection(inspectionTableData);
    return inspection;
}

function buildInstructionNotes(secondColumnSheet: DataRow[]) {
    const coatingSpecTableStartIndex = findSectionStartIndexWithRegex(secondColumnSheet, 'Coating width');
    const coatingSpecTableTitleIndex = coatingSpecTableStartIndex > 0 ? coatingSpecTableStartIndex - 1 : coatingSpecTableStartIndex;

    let noteText = '';
    if (coatingSpecTableTitleIndex == 0) {
        noteText = getCellsBeforeKeyword(secondColumnSheet[coatingSpecTableTitleIndex], 'coating').filter(cell => cell != null).join('\n');
    } else {
        noteText = toExtractNotes(secondColumnSheet, coatingSpecTableTitleIndex);
    }
    return noteText;
}

function findFirstEmptyRowAfter(startIndex: number, data: any[][]): number {
    for (let i = startIndex + 1; i < data.length; i++) {
        const row = data[i];
        const isEmpty = !row || row.every(cell => cell === null || cell === undefined || String(cell).trim() === "");
        if (isEmpty) {
            return i;
        }
    }
    return -1; // No empty row found
}

function buildYieldTables(secondColumnSheet: DataRow[]): {
    processAmountTable: ProcessYield;
    materialAmountTable: AmountYield[];
} | null {
    const processStart = findSectionStartIndexWithRegex(secondColumnSheet, 'Process');
    const materialStart = findSectionStartIndexWithRegex(secondColumnSheet, 'Net amount');

    if (processStart === -1 || materialStart === -1) {
        console.warn("Required section(s) not found.");
        return null;
    }

    const isHorizontal = processStart === materialStart;

    if (isHorizontal) {
        const firstEmptyIndex = findFirstEmptyRowAfter(materialStart, secondColumnSheet);
        const fullTable = extractSectionDataWithIndices(secondColumnSheet, materialStart, firstEmptyIndex);
        const processColIdx = fullTable[0].findIndex(cell => String(cell).toLowerCase() === "process");

        if (processColIdx === -1) {
            console.warn("Process column not found in horizontal layout.");
            return null;
        }

        const materialTable = fullTable.map(row => row.slice(0, processColIdx));
        const processTable = fullTable.map(row => row.slice(processColIdx));

        return {
            processAmountTable: convertToProcessYield(processTable),
            materialAmountTable: convertToMaterialAmount(materialTable),
        };
    }

    // vertical layout
    const materialTable = extractSectionDataWithIndices(secondColumnSheet, materialStart, processStart);
    const firstEmptyIndex = findFirstEmptyRowAfter(processStart, secondColumnSheet);
    const processTable = extractSectionDataWithIndices(secondColumnSheet, processStart, firstEmptyIndex);

    return {
        processAmountTable: convertToProcessYield(processTable),
        materialAmountTable: convertToMaterialAmount(materialTable),
    };
}

/**
 * FilePreviewService Class
 */
export class WorkOrderPreviewService implements WorkOrderPreviewServiceInterface {

    /**
     * Generates HTML preview of the Excel file.
     *
     * @param fileUrl - The URL of the Excel file to preview.
     * @returns A promise that resolves to the HTML string representing the preview.
     */

    async getFilePreviewData(fileUrl: string): Promise<WorkOrderPreviewData> {
        try {
            const response = await fetch(fileUrl);
            if (!response.ok) throw new Error(`Failed to fetch the file. Status: ${response.status}`);

            const arrayBuffer = await response.arrayBuffer();
            const workbook = XLSX.read(arrayBuffer, { type: "array" });

            checkWorkbookProtection(workbook);

            // Identify the target sheet 
            const targetRegex = /(anode|cathode)/i;
            const matchedSheetNames = workbook.SheetNames.filter(name => targetRegex.test(name));
            if (matchedSheetNames.length === 0) throw new Error("No matching sheet found in the Excel file.");

            const workOrderSheetName = this.findWorkOrderSheetName(matchedSheetNames, workbook);
            if (!workOrderSheetName) throw new Error("No work order sheet found.");

            console.log("Work order sheet found:", workOrderSheetName);

            const worksheet = workbook.Sheets[workOrderSheetName];
            const firstColumnSheet = getSheetData(worksheet, { s: { r: 0, c: 1 }, e: { r: 40, c: 9 } });
            const secondColumnSheet = getSheetData(worksheet, { s: { r: 0, c: 11 }, e: { r: 50, c: 17 } });

            const paddedMixingTableData = buildMixingTable(firstColumnSheet);
            // call function in mixing table component to get paddedMixingTableData and convert it into binder and slurry mixing
            const builtMixingTable = parseMixingTableData(paddedMixingTableData);

            const inspection = buildInspectionTable(firstColumnSheet);

            const specTables = mapTableToCoatingAndCalenderingSpec(secondColumnSheet);
            const insructionNote = buildInstructionNotes(secondColumnSheet);

            const materialAmountTableStartIndex = findSectionStartIndex(secondColumnSheet, 'Net amount');
            const processTableStartIndex = findSectionStartIndex(secondColumnSheet, 'Process')

            if (materialAmountTableStartIndex == -1 || processTableStartIndex == -1) {
                console.log("Couldn't find a yield table")
            }

            const { processAmountTable, materialAmountTable } = buildYieldTables(secondColumnSheet) || {};

            return {
                binderMixingTable: builtMixingTable.binderMixing,
                slurryMixingTable: builtMixingTable.slurryMixing,
                noteText: insructionNote,
                inspection,
                processYieldData: processAmountTable,
                materialsNeeded: materialAmountTable,
                coatingSpecTable: specTables.coating,
                calenderingSpecTable: specTables.calendering,
                slittingSpecTable: specTables.slitting,
                mixingTableData: paddedMixingTableData,

            };
        } catch (error) {
            console.error("Error generating file preview:", error);
            throw error;
        }
    }

    /**
     * Finds the first matching work order sheet.
     */
    private findWorkOrderSheetName(sheetNames: string[], workbook: any): string | null {
        for (const sheetName of sheetNames) {
            if (getWorkOrderSheetName(sheetName, workbook)) return sheetName;
        }
        return null;
    }


}
