
import { AmountYield, Measurement } from "@twinsketch/topika-model";
import React from "react";
import 'src/styles/workOrder/work-order.css';
import 'src/styles/workOrder/mixing-table.css';


interface AmountYieldTableProps {
    materialsNeeded: AmountYield[] | null | undefined;
}

const formatNumber = (value: number | null | undefined) => {
    return typeof value === "number" ? value.toFixed(2) : "N/A";
};

const AmountYieldsTable: React.FC<AmountYieldTableProps> = ({ materialsNeeded }) => {

    if (!materialsNeeded || materialsNeeded.length === 0) {
        return <p className="text-gray-500">No materials data available.</p>;
    }

    return (
        <table className="mixing-table" style={{ width: "440px" }}>
            <thead>
                <tr>
                    <th>Material</th>
                    <th>Net (kg)</th>
                    <th>Min (kg)</th>
                </tr>
            </thead>
            <tbody>
                {materialsNeeded.map((material, index) => (
                    <tr key={`${material.materialName}-${index}`}>
                        <td>{material.materialName || "N/A"}</td>
                        <td>{formatNumber(material.net)}</td>
                        <td>{formatNumber(material.min)}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default AmountYieldsTable